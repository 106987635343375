import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAllRequests from './ViewAllRequests';
import { CSVLink } from "react-csv";

function PremiumBannerRequest() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [uid, setUid] = useState([]);
    const [uuid, setUuid] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const headers = [
        { label: "Business/Owner Name", key: "parlour_name" },
        { label: "Phone No", key: "phone_no" },
        { label: "Size", key: "size" },
        { label: "Whatsapp No", key: "whatsapp_no" },
        { label: "Message", key: "message" },
        { label: "Address", key: "address" },
        { label: "Description", key: "description" },
        { label: "Payment", key: "payment" },
        { label: "Payment ID", key: "payment_id" },
        { label: "Payment Status", key: "payment_status" },
        { label: "Email", key: "email" },
        { label: "Created Date", key: "created_date", dataFormat: "MM/DD/YY, h:mm a" },
    ];

    const [data, setData] = useState([])
    const [csvdata, setCsvdata] = useState([]);
    const [redID, setRegID] = useState("");

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    const [doneCount, setDoneCount] = useState(0);

    useEffect(() => {
        const fetchData = () => {
            try {
                db.collection("premium_banner_request")
                    .orderBy("created_date", "desc")
                    .onSnapshot((snapshot) => {
                        const dataArray = [];
                        let count = 0;

                        setCsvdata(
                            snapshot.docs.map((doc) => ({
                                id: doc.id,
                                data: doc.data()
                            }))
                        );

                        snapshot.forEach((doc) => {
                            const documentData = {
                                id: doc.id,
                                data: doc.data(),
                            };

                            dataArray.push(documentData);

                            // Check the status field and increment count
                            if (documentData.data.status === '1') {
                                count += 1;
                            }
                        });

                        setData(dataArray);
                        setDoneCount(count);
                    });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    // useEffect(() => {
    //     db.collection("premium_banner_request").orderBy("created_date", "desc").onSnapshot((snapshot) => {
    //         setCsvdata(
    //             snapshot.docs.map((doc) => ({
    //                 id: doc.id,
    //                 data: doc.data()
    //             }))
    //         );

    //         setData(
    //             snapshot.docs.map((doc) => (
    //                 doc.data()
    //             ))
    //         );

    //     });

    // }, []);


    const notifyApp = (msg) => {
        // Call the user's CollectionReference to add a new user
        db.collection("app_notification").add({ 'message': msg, 'link': "Advertise", 'status': "0", "uid": uuid, 'created_date': new Date() }).then(() => {
            console.log("notified");
        })
    }


    const initialFormStateFeedback = { status: '' }
    const [submit, setSubmit] = useState(initialFormStateFeedback)

    const handleChange2 = async (e, msg) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
        if (e.target.value == "1") {
            db.collection("premium_banner_request").doc(uid).update({ 'status': "1", 'updated_date': new Date() }).then(() => {
                console.log("user added");
            })
            notifyApp(msg = `Hi ${name} we have created your Premium Banner, please go to advertise section to download.`);
            sendWhatsapp()
        } else {
            db.collection("premium_banner_request").doc(uid).update({ 'status': "0", 'updated_date': new Date() }).then(() => {
                console.log("user added");
            })
        }
    }

    const collect = (id, name, mainID, phone) => {
        setUid(id)
        setName(name)
        setUuid(mainID)
        setPhone(phone)

        db.collection("notification_ids")
            .where('uid', '==', mainID)
            .onSnapshot((snapshot) => {
                snapshot.forEach((doc) => {
                    const regId = doc.data().reg_id;
                    setRegID(doc.data().reg_id);
                    console.log('reg_id:', regId);
                    // If you want to store the reg_id values in a state variable
                    // or use it elsewhere, you can set it accordingly.
                });
            });
    }

    const sendWhatsapp = (e) => {
        console.log("wahtsap calling")
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer EABMKlZCrATXIBAPOZBZBPqsdfot34fq0FTipZBQf3RiXZAapyCOqMay09HfhSxoB8bd5ZA7RUnLWhd0JkXtACJQ682THiDEMhQhyPOkeui8Cb8P72WPDhihUZBCkHtPLSgsmuZA6iRY6VunDDIjPA5mATQQITVXOECHqkZB5E2pzWSC2AaUp0wZCWR");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(
            {
                "messaging_product": "whatsapp",
                "recipient_type": "individual",
                "to": `91${phone}`,
                "type": "template",
                "template": {
                    "name": "visiting_card_done",
                    "language": {
                        "code": "en_US",
                        "policy": "deterministic"
                    },
                    "components": [
                        {
                            "type": "header",
                            "parameters": [
                                {
                                    "type": "image",
                                    "image": { "link": "https://firebasestorage.googleapis.com/v0/b/youvati.appspot.com/o/ss.jpg?alt=media&token=ad2688bb-fef3-4b28-8f02-59e73c4a4cab" }
                                }
                            ]
                        },
                        {
                            "type": "body",
                            "parameters": [
                                { "type": "text", "text": `${name}` },
                                { "type": "text", "text": "Premium Banner" },
                            ]
                        }
                    ]
                }
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://graph.facebook.com/v14.0/104489945783566/messages", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => {
                console.log('error', error)
            });
    };


    const handleSubmitPush = () => {
        // Use the single registration ID
        const singleRegId = redID; // Replace with your actual registration ID

        console.log(singleRegId);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "key=AAAAnzqDoGw:APA91bHNYZDEZn5paZqVOV6evl4npGLdy7V1iixsNCGekPti0mkcTft5lHb9WruMaFjOdJrGVR9kkFzfC00HMfmvPUFo4sQhvXmXoom7clqOhXqMc7y5erUF75zJIxAzMZeuCtXx6PTS");

        // Check if the registration ID is valid before proceeding
        if (!singleRegId || singleRegId.trim() === "") {
            console.log("No valid registration ID to send the push notification.");
            return;
        }

        const raw = JSON.stringify({
            "registration_ids": [singleRegId],
            "notification": {
                "body": "Download karo, Share karo, Grow karo 🥳",
                "title": "Your Premium Banner Card Is Ready!",
                "android_channel_id": "youvati",
                "image": "",
                "sound": true
            },
            "data": {
                "type": "msg",
                "id": "advertise",
                "image": ""
            }
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://fcm.googleapis.com/fcm/send", requestOptions)
            .then(response => response.json())
            .then(data => {
                // Handle the response as needed
                console.log(data);

                // Additional handling if required
                if (data.success) {
                    // Swal.fire(`Push notification sent successfully to ${singleRegId}.`);
                    console.log(`Push notification sent successfully to ${singleRegId}.`)
                } else {
                    // Swal.fire(`Failed to send push notification to ${singleRegId}.`);
                    console.log(`Failed to send push notification to ${singleRegId}.`)
                }
            })
            .catch(error => console.log('error', error));
    };

    const csvExcelData = csvdata.map(({ id, data }, index) => ({
        parlour_name: data.parlour_name,
        phone_no: data.phone_no,
        size: data.size,
        whatsapp_no: data.whatsapp_no,
        message: data.message,
        address: data.address,
        description: data.description,
        payment: data.payment,
        payment_id: data.payment_id,
        payment_status: data.payment_status,
        email: data.email,
        created_date: data.created_date,
    }));


    const [dataDict, setDataDict] = useState({}); // Initialize dataDict as an empty object

    useEffect(() => {
        db.collection("personal_details")
            .orderBy("created_date", "desc")
            .onSnapshot((snapshot) => {
                const newDataDict = {}; // Create a new object to store updated data
                snapshot.docs.forEach((doc) => {
                    const data = doc.data();
                    newDataDict[data.phone_no] = data.full_name;
                });
                setDataDict(newDataDict); // Update the state with the new object
                console.log(newDataDict)
            });
    }, []);


    return (
        <>
            {/* <ViewAllRequests /> */}

            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
                    <CSVLink data={csvExcelData} headers={headers} filename={"premium.csv"}>
                        <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
                    </CSVLink>
                </div>
                <div class="">


                    <div class="p-2">
                        <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                            {/* <Link to="/admin_panel" class="btn bg-gradient-primary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Go Back</Link> */}

                        </div>
                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">Premium Banner Request</h6>
                            </div>
                            <div class="d-flex p-2" style={{ justifyContent: "center", fontWeight: "bold" }}>
                                Total : {data.length} || Pending : {data.length - doneCount} || Done : {doneCount}
                            </div>
                            <div class="">
                                {/* <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                        <a class="btn bg-gradient-primary btn-sm mb-0" style={{ color: "white" }} onClick={() => changeStatus()}>+&nbsp; Add Rent Details</a>
                    </div> */}
                                <div class="card">
                                    <div class="table-responsive fix-width scroll-inner">
                                        <table class="table table-bordered table-hover">
                                            <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                                <tr>
                                                    <th class="col">Owner Name</th>
                                                    <th class="col">Requested Name</th>
                                                    <th class="col ps-2">Phone No</th>
                                                    <th class="col ps-2">Size</th>
                                                    <th class="col ps-2">Whatsapp No</th>
                                                    <th class="col ps-2">Address</th>
                                                    <th class="col ps-2">Payment</th>
                                                    <th class="col ps-2">Payment Status</th>
                                                    <th class="col ps-2">Payment ID</th>
                                                    <th class="col ps-2">Message</th>
                                                    <th class="col ps-2">Email</th>
                                                    <th class="col ps-2">Created Date</th>
                                                    <th class="col ps-2">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {csvdata?.map(({ id, data }, index) => (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                <p class="text-xs font-weight-bold mb-0">{dataDict[data.phone_no]}</p>

                                                            </td>
                                                            <td>
                                                                <p class="text-xs font-weight-bold mb-0">{data.parlour_name}</p>
                                                                {/* <p class="text-xs text-secondary mb-0">24 Nov 2022</p> */}
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.phone_no}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.size}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.whatsapp_no}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td style={{ whiteSpace: 'pre-wrap' }}>
                                                                <p class="text-xs font-weight-bold mb-0" style={{ width: "15rem" }}>{data.address}</p>

                                                            </td>

                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.payment}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs" style={{ color: data.payment_status === "Payment Failed" ? "red" : "green" }}>{data.payment_status}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.payment_id}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td style={{ whiteSpace: 'pre-wrap' }}>
                                                                <p class="text-xs font-weight-bold mb-0" style={{ width: "15rem" }}>{data.message}</p>

                                                            </td>

                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.email}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td class="align-middle text-center text-sm">
                                                                <span class="text-secondary text-xs font-weight-normal">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</span>
                                                            </td>

                                                            {data.status == null ? <>
                                                                <td style={{ whiteSpace: "nowrap" }}>
                                                                    <select class="form-control" id={index} name="status" onClick={() => collect(id, data.parlour_name, data.uid, data.phone_no)} onChange={handleChange2} value={data.status} style={{ padding: "7px", width: "9rem", backgroundColor: data.status == 1 ? "#28a745" : "transparent" }} disabled={data.status == 1 ? true : false} >
                                                                        <option selected={data.status === "0" ? true : false} value="0">Pending</option>
                                                                        <option selected={data.status === "1" ? true : false} value="1">Done</option>
                                                                    </select>
                                                                </td>
                                                            </> : <>
                                                                <td style={{ whiteSpace: "nowrap" }}>
                                                                    <select class="form-control" id="request_status" name="request_status" style={{ width: "8rem", background: "green", color: "white" }}>
                                                                        <option selected value="Done" disabled>Done</option>
                                                                    </select>
                                                                </td>
                                                            </>}


                                                        </tr>

                                                    </>
                                                ))}


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </main>


        </>
    )
}

export default PremiumBannerRequest;