import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import { useNavigate, Link, useParams } from "react-router-dom"
import db from "./Firebase";
import axios from "axios";
// import { firebase, auth } from './FirebaseLogin';
import { arrayUnion, arrayRemove } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { CSVLink } from "react-csv";
// const fileInputRef = useRef(null);
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


function LoanDetails() {
    let params = useParams();
    const [fileKey, setFileKey] = useState(0);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true)
    const [personalData, setPersonalData] = useState({});
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [uid, setUid] = useState('');
    const [logs, setLogs] = useState([]);
    const [remarks, setRemarks] = useState([]);
    const [slots, setSlots] = useState({});
    const [slotsID, setSlotsID] = useState({});
    const [csvdata, setCsvdata] = useState([]);
    const [selectedSlots, setSelectedSlots] = useState([]);
    const [email, setEmail] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date());

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    useEffect(() => {
        const storedEmail = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");
        setEmail(storedEmail);

        if (storedRemember === "false" || !storedEmail || !storedPassword) {
            navigate('/signin');
        }

    }, []);


    const headers = [
        { label: "Applicant Name", key: "applicant_name" },
        { label: "Applicant Name", key: "applicant_phone" },
        { label: "loan_id", key: "loan_id" },
        { label: "applicant_date_of_birth", key: "applicant_date_of_birth" },
        { label: "loan_type", key: "loan_type" },
        { label: "gender", key: "gender" },
        { label: "applicant_alternate_phone", key: "applicant_alternate_phone" },
        { label: "applicant_marital_status", key: "applicant_marital_status" },
        { label: "fathers_name", key: "fathers_name" },
        { label: "mothers_name", key: "mothers_name" },
        { label: "city", key: "city" },
        { label: "state", key: "state" },
        { label: "pincode", key: "pincode" },
        { label: "full_address", key: "full_address" },
        { label: "highest_education", key: "highest_education" },
        { label: "business_type", key: "business_type" },
        { label: "parlour_freelancer_name", key: "parlour_freelancer_name" },
        { label: "parlour_address", key: "parlour_address" },
        { label: "ownership_type", key: "ownership_type" },
        { label: "working_since", key: "working_since" },
        { label: "monthly_since", key: "monthly_since" },
        { label: "bank_account_number", key: "bank_account_number" },
        { label: "bank_name", key: "bank_name" },
        { label: "ifsc_code", key: "ifsc_code" },
        { label: "upi_id", key: "upi_id" },
        { label: "loan_amount", key: "loan_amount" },
        { label: "have_you_taken_loan_in_past", key: "have_you_taken_loan_in_past" },
        { label: "needGrant", key: "needGrant" },
        { label: "purposeOfReason", key: "purposeOfReason" },
        { label: "reasonEstimated", key: "reasonEstimated" },
        { label: "repaymentModel", key: "repaymentModel" },
        { label: "how_did_you_know_about_scheme", key: "how_did_you_know_about_scheme" },
        { label: "aadhar_front", key: "aadhar_front" },
        { label: "aadhar_back", key: "aadhar_back" },
        { label: "pan", key: "pan" },
        { label: "passbook", key: "passbook" },
        { label: "parlour_photo", key: "parlour_photo" },
        { label: "applicant_photo", key: "applicant_photo" },
        { label: "error_message", key: "error_message" },
        { label: "document_status", key: "document_status" },
        { label: "document_status_done_msg", key: "document_status_done_msg" },
        { label: "document_status_pending_msg", key: "document_status_pending_msg" },
        { label: "verification_status", key: "verification_status" },
        { label: "verification_status_done_msg", key: "verification_status_done_msg" },
        { label: "verification_status_pending_msg", key: "verification_status_pending_msg" },
        { label: "nbfc_status", key: "nbfc_status" },
        { label: "nbfc_status_done_msg", key: "nbfc_status_done_msg" },
        { label: "nbfc_status_pending_msg", key: "nbfc_status_pending_msg" },
        { label: "penny_check_status", key: "penny_check_status" },
        { label: "penny_check_status_done_msg", key: "penny_check_status_done_msg" },
        { label: "penny_check_status_pending_msg", key: "penny_check_status_pending_msg" },
        { label: "godrej_status", key: "godrej_status" },
        { label: "godrej_status_done_msg", key: "godrej_status_done_msg" },
        { label: "godrej_status_pending_msg", key: "godrej_status_pending_msg" },
        { label: "loan_disbursement_status", key: "loan_disbursement_status" },
        { label: "loan_disbursement_status_done_msg", key: "loan_disbursement_status_done_msg" },
        { label: "loan_disbursement_status_pending_msg", key: "loan_disbursement_status_pending_msg" },
        { label: "custom_msg_one", key: "custom_msg_one" },
        { label: "custom_msg_two", key: "custom_msg_two" },
        { label: "status", key: "status" },
        { label: "created_date", key: "created_date", dataFormat: "MM/DD/YY, h:mm a" },
        { label: "updated_date", key: "updated_date", dataFormat: "MM/DD/YY, h:mm a" },
    ];

    const handleSlotSelection = (slot) => {
        if (selectedSlots.length === 2) {
            if (selectedSlots.includes(slot)) {
                setSelectedSlots(selectedSlots.filter((selectedSlot) => selectedSlot !== slot));
            } else {

                alert("You can only select two slots")
            }
        } else {
            if (selectedSlots.includes(slot)) {
                setSelectedSlots(selectedSlots.filter((selectedSlot) => selectedSlot !== slot));
            } else {
                setSelectedSlots([...selectedSlots, slot]);
            }
        }

    };

    const timeSlots = ['9:00 AM', '10:00 AM', '11:00 AM', '12:00 PM', '1:00 PM', '2:00 PM'];


    const openModalNew = () => {
        window.openModelNew();
    }



    useEffect(() => {
        db.collection("loan_logs").where('phone', '==', params.id).orderBy("updated_date", "desc").onSnapshot((snapshot) => {
            setLogs(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );

        });

        db.collection("loan_extra_data").where('phone', '==', params.id).onSnapshot((snapshot) => {
            setRemarks(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );

        });


        db.collection("time_slot").where('phone', '==', params.id).onSnapshot((snapshot) => {
            snapshot.docs.map(e => { setSlots(e.data()) })
            setSlotsID(snapshot.docs.map((doc) => ({
                id: doc.id,
            })))
            // console.log("---------");
            // console.log(slots?.selected_time);
            // setSlots(
            //     snapshot.docs.map((doc) => ({
            //         id: doc.id,
            //         data: doc.data(),
            //     }))
            // );
        });
        // db.collection("loan").where('username', '==', params.id.toLowerCase()).onSnapshot((snapshot) => {
        db.collection("loan").where('applicant_phone', '==', params.id).onSnapshot((snapshot) => {
            snapshot.docs.map(e => setPersonalData(e.data()))
            // alert(snapshot.docs)
            setUid(snapshot.docs[0].id)
            if (snapshot.docs.length == 0) {
                navigate('/error');
            } else {
                // alert("exists")
                setIsLoading(false);
            }
        });
        setSubmit({ id: '', document_status: personalData.document_status, verification_status: personalData.verification_status, nbfc_status: personalData.nbfc_status, penny_check_status: personalData.document_status, penny_check_status: personalData.godrej_status, loan_disbursement_status: personalData.loan_disbursement_status, status: personalData.status, updated_date: new Date() })



    }, []);

    const openModalShow = () => {
        // alert("ooo")
        window.openModelshow();
    }

    const changeStatus = () => {

        openModalShow();
        // alert(submit.status)
    }

    const initialFormStateFeedback = { id: '', document_status: '', verification_status: '', nbfc_status: '', penny_check_status: '', godrej_status: '', loan_disbursement_status: '', status: '', updated_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback)
    const [name, setName] = useState("")

    const initialFormStateRepayment = { all_payment_done: '', loan_amount: '', loan_id: "", loan_disbursement_date: new Date(), emi_date_1: '', emi_date_2: '', emi_date_3: '', emi_date_4: '', emi_date_5: '', amount_1: '', amount_2: '', amount_3: '', amount_4: '', amount_5: '', payment_link_1: '', payment_link_2: '', payment_link_3: '', payment_link_4: '', payment_link_5: '', paid_on_1: '', paid_on_2: '', paid_on_3: '', paid_on_4: '', paid_on_5: '', status_1: '', status_2: '', status_3: '', status_4: '', status_5: '', }
    const [repay, setRepay] = useState(initialFormStateRepayment)


    const handleChangeRepay = (e) => {
        const { id, value } = e.target
        setRepay({ ...repay, [id]: value })
        console.log(e.target.value)
    }

    const timeSlot = (msg) => {
        db.collection("time_slot").doc(slotsID[0]?.id).delete().then(() => {
            console.log('Deleted Successfully');
        })
        db.collection("time_slot").add({ 'time_slot': selectedSlots, 'type': 'video_call', 'phone': params.id, 'updated_date': new Date(), 'selected_date': selectedDate, 'selected_time': "" }).then(() => {
            console.log("user added");
            openModalNew();
            db.collection("loan_logs").add({ 'name': "Done", 'type': 'Time Slot Allocated', 'message': `Time Slot Allocated To User`, 'updated_date': new Date(), 'phone': params.id }).then(() => {
                console.log("data added");
            })
            notifyApp(msg = "Time Slot Allocated for verification. Please check");
        })
    }

    const [views, setViews] = useState('remarks');

    const view = () => {
        console.log(views)
        if (views === "remarks") {
            setViews("logs")
        }
    }

    const viewR = () => {
        if (views === "logs") {
            setViews("remarks")
        }
    }

    // CollectionReference appNotification =
    // FirebaseFirestore.instance.collection('app_notification');

    const notifyApp = (msg) => {
        // Call the user's CollectionReference to add a new user
        db.collection("app_notification").add({ 'message': msg, 'link': "UnderReviewPage", 'status': "0", "uid": personalData.uid, 'created_date': new Date() }).then(() => {
            console.log("user added");
        })
    }



    const handleChange1 = async (e, msg) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
        // alert(e.target.name)
        setName(e.target.name)
        if (e.target.value == "2") {
            const { value: text } = await Swal.fire({
                input: 'textarea',
                inputLabel: 'Message',
                inputPlaceholder: 'Type your message here...',
                inputAttributes: {
                    'aria-label': 'Type your message here'
                },
                showCancelButton: true
            })
            // Swal.fire(name)
            db.collection("loan").doc(uid).update({ 'document_status': parseInt(e.target.value), 'error_message': text, 'updated_date': new Date() }).then(() => {
                console.log("user added");
            })
            db.collection("loan_logs").add({ 'name': "Rejected", 'type': 'Document Verification', 'message': `Document verification rejected :- ${text}`, 'updated_date': new Date(), 'phone': params.id }).then(() => {
                console.log("data added");
            })

            notifyApp(msg = "Your Document verification is rejected.");

        } else if (e.target.value == "1") {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, update it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    db.collection("loan").doc(uid).update({ 'document_status': parseInt(e.target.value), 'error_message': "", 'updated_date': new Date() }).then(() => {
                        console.log("user added");
                    })
                    db.collection("loan_logs").add({ 'name': "Done", 'type': 'Document Verification', 'message': "Document verified successfully", 'updated_date': new Date(), 'phone': params.id }).then(() => {
                        console.log("data added");
                    })
                    notifyApp(msg = "Your Document verified successfully.");
                    Swal.fire('Updated!', '', 'success')
                }
            })

        } else {
            db.collection("loan").doc(uid).update({ 'document_status': parseInt(e.target.value), 'error_message': "", 'updated_date': new Date() }).then(() => {
                console.log("user added");
            })
        }




        //  else if (name === 'verification_status') {
        //     Swal.fire(name)
        //     db.collection("loan").doc(uid).update({ 'verification_status': parseInt(e.target.value), 'message': text, 'updated_date': new Date() }).then(() => {
        //         console.log("user added");
        //     })

        // } else if (name === 'nbfc_status') {
        //     // Swal.fire(name)
        //     db.collection("loan").doc(uid).update({ 'nbfc_status': parseInt(e.target.value), 'message': text, 'updated_date': new Date() }).then(() => {
        //         console.log("user added");
        //     })

        // }
        // else if (name === 'penny_check_status') {
        //     db.collection("loan").doc(uid).update({ 'penny_check_status': parseInt(e.target.value), 'message': text, 'updated_date': new Date() }).then(() => {
        //         console.log("user added");
        //     })

        // }
        // else if (name === 'godrej_status') {
        //     db.collection("loan").doc(uid).update({ 'godrej_status': parseInt(e.target.value), 'message': text, 'updated_date': new Date() }).then(() => {
        //         console.log("user added");
        //     })

        // }
        // else if (name === 'loan_disbursement_status') {
        //     db.collection("loan").doc(uid).update({ 'loan_disbursement_status': parseInt(e.target.value), 'message': text, 'updated_date': new Date() }).then(() => {
        //         console.log("user added");
        //     })
        // }

        // if (!text) {
        // Swal.fire(name)
        //     db.collection("loan").doc(uid).update({ document_status: parseInt(e.target.value), 'message': text, 'updated_date': new Date() }).then(() => {
        //         console.log("user added");
        //     })
        // }
    }

    const handleChange2 = async (e, msg) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
        // alert(e.target.name)
        setName(e.target.name)
        if (e.target.value == "2") {
            const { value: text } = await Swal.fire({
                input: 'textarea',
                inputLabel: 'Message',
                inputPlaceholder: 'Type your message here...',
                inputAttributes: {
                    'aria-label': 'Type your message here'
                },
                showCancelButton: true
            })
            // Swal.fire(name)
            db.collection("loan").doc(uid).update({ 'verification_status': parseInt(e.target.value), 'error_message': text, 'updated_date': new Date() }).then(() => {
                console.log("user added");
            })
            db.collection("loan_logs").add({ 'name': "Rejected", 'type': 'Video Call Verification', 'message': `Video call verification rejected : ${text}`, 'updated_date': new Date(), 'phone': params.id }).then(() => {
                console.log("data added");
            })
            notifyApp(msg = "Your video call verification is rejected.");
        } else if (e.target.value == "1") {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, update it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    db.collection("loan").doc(uid).update({ 'verification_status': parseInt(e.target.value), 'error_message': "", 'updated_date': new Date() }).then(() => {
                        console.log("user added");
                    })
                    db.collection("loan_logs").add({ 'name': "Done", 'type': 'Video Call Verification', 'message': `Video call verified successfully`, 'updated_date': new Date(), 'phone': params.id }).then(() => {
                        console.log("data added");
                    })
                    db.collection("time_slot").doc(slotsID[0].id).delete().then(() => {
                        console.log('Deleted Successfully');
                    })
                    notifyApp(msg = "Your video call verified successfully");
                    Swal.fire('Updated!', '', 'success')
                }
            })

            // setSelectedSlots([])

        } else if (e.target.value == "3") {
            changeStatus()
        }
        else {
            db.collection("loan").doc(uid).update({ 'verification_status': parseInt(e.target.value), 'error_message': "", 'updated_date': new Date() }).then(() => {
                console.log("user added");
            })
        }



    }

    const handleChange3 = async (e, msg) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
        // alert(e.target.name)
        setName(e.target.name)
        if (e.target.value == "2") {
            const { value: text } = await Swal.fire({
                input: 'textarea',
                inputLabel: 'Message',
                inputPlaceholder: 'Type your message here...',
                inputAttributes: {
                    'aria-label': 'Type your message here'
                },
                showCancelButton: true
            })
            // Swal.fire(name)
            db.collection("loan").doc(uid).update({ 'nbfc_status': parseInt(e.target.value), 'error_message': text, 'updated_date': new Date() }).then(() => {
                console.log("user added");
            })
            db.collection("loan_logs").add({ 'name': "Rejected", 'type': 'Physical Verification', 'message': `Physical verification rejected : ${text}`, 'updated_date': new Date(), 'phone': params.id }).then(() => {
                console.log("data added");
            })
            notifyApp(msg = "Your physical verification is rejected");
        } else if (e.target.value == "1") {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, update it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    db.collection("loan").doc(uid).update({ 'nbfc_status': parseInt(e.target.value), 'error_message': "", 'updated_date': new Date() }).then(() => {
                        console.log("user added");
                    })
                    db.collection("loan_logs").add({ 'name': "Done", 'type': 'Physical Verification', 'message': 'Physical verification done', 'updated_date': new Date(), 'phone': params.id }).then(() => {
                        console.log("data added");
                    })
                    notifyApp(msg = "Your physical verification is done");
                    Swal.fire('Updated!', '', 'success')
                }
            })

        } else {
            db.collection("loan").doc(uid).update({ 'nbfc_status': parseInt(e.target.value), 'error_message': "", 'updated_date': new Date() }).then(() => {
                console.log("user added");
            })
        }

    }

    const handleChange4 = async (e, msg) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
        // alert(e.target.name)
        setName(e.target.name)
        if (e.target.value == "2") {
            const { value: text } = await Swal.fire({
                input: 'textarea',
                inputLabel: 'Message',
                inputPlaceholder: 'Type your message here...',
                inputAttributes: {
                    'aria-label': 'Type your message here'
                },
                showCancelButton: true
            })
            // Swal.fire(name)
            db.collection("loan").doc(uid).update({ 'penny_check_status': parseInt(e.target.value), 'error_message': text, 'updated_date': new Date() }).then(() => {
                // console.log("user added");
            })
            db.collection("loan_logs").add({ 'name': "Rejected", 'type': 'Penny Check Verification', 'message': `Penny check verification rejected : ${text}`, 'updated_date': new Date(), 'phone': params.id }).then(() => {
                console.log("data added");
            })
            notifyApp(msg = "Your Penny check verification is rejected");
        } else if (e.target.value == "1") {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, update it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    db.collection("loan").doc(uid).update({ 'penny_check_status': parseInt(e.target.value), 'error_message': "", 'updated_date': new Date() }).then(() => {
                        // console.log("user added");
                    })
                    db.collection("loan_logs").add({ 'name': "Done", 'type': 'Penny Check Verification', 'message': 'Penny Check verified successfully', 'updated_date': new Date(), 'phone': params.id }).then(() => {
                        console.log("data added");
                    })
                    notifyApp(msg = "Your Penny check is verified successfully");
                    Swal.fire('Updated!', '', 'success')
                }
            })

        } else {
            db.collection("loan").doc(uid).update({ 'penny_check_status': parseInt(e.target.value), 'error_message': "", 'updated_date': new Date() }).then(() => {
                // console.log("user added");
            })
        }

    }

    const handleChange5 = async (e, msg) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
        // alert(e.target.name)
        setName(e.target.name)

        if (e.target.value == "2") {
            const { value: text } = await Swal.fire({
                input: 'textarea',
                inputLabel: 'Message',
                inputPlaceholder: 'Type your message here...',
                inputAttributes: {
                    'aria-label': 'Type your message here'
                },
                showCancelButton: true
            })
            // Swal.fire(name)
            db.collection("loan").doc(uid).update({ 'godrej_status': parseInt(e.target.value), 'error_message': text, 'updated_date': new Date() }).then(() => {
                // console.log("user added");
            })
            db.collection("loan_logs").add({ 'name': "Rejected", 'type': 'Bank Verification', 'message': `Bank verification rejected : ${text}`, 'updated_date': new Date(), 'phone': params.id }).then(() => {
                console.log("data added");
            })
            notifyApp(msg = "Your Bank verification is rejected");
        } else if (e.target.value == "1") {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, update it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    db.collection("loan").doc(uid).update({ 'godrej_status': parseInt(e.target.value), 'error_message': "", 'updated_date': new Date() }).then(() => {
                        // console.log("user added");
                    })
                    db.collection("loan_logs").add({ 'name': "Done", 'type': 'Bank Verification', 'message': 'Bank verification successful', 'updated_date': new Date(), 'phone': params.id }).then(() => {
                        console.log("data added");
                    })
                    notifyApp(msg = "Your Bank verification is successful");
                    Swal.fire('Updated!', '', 'success')
                }
            })

            // setSelectedSlots([]);
        }
        else if (e.target.value == "3") {
            changeStatus()
        }
        else {
            db.collection("loan").doc(uid).update({ 'godrej_status': parseInt(e.target.value), 'error_message': "", 'updated_date': new Date() }).then(() => {
                // console.log("user added");
            })
        }

    }

    const handleChange6 = async (e, msg) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
        // alert(e.target.name)
        setName(e.target.name)
        if (e.target.value == "2") {
            const { value: text } = await Swal.fire({
                input: 'textarea',
                inputLabel: 'Message',
                inputPlaceholder: 'Type your message here...',
                inputAttributes: {
                    'aria-label': 'Type your message here'
                },
                showCancelButton: true
            })
            // Swal.fire(name)
            db.collection("loan").doc(uid).update({ 'loan_disbursement_status': parseInt(e.target.value), 'error_message': text, 'updated_date': new Date() }).then(() => {
                // console.log("user added");
            })
            db.collection("loan_logs").add({ 'name': "Rejected", 'type': 'Loan Disbursement', 'message': `Loan Disbursement rejected : ${text}`, 'updated_date': new Date(), 'phone': params.id }).then(() => {
                console.log("data added");
            })
            notifyApp(msg = "Your Loan Disbursement is rejected");
        } else if (e.target.value == "1") {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, update it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    db.collection("loan").doc(uid).update({ 'loan_disbursement_status': parseInt(e.target.value), 'error_message': "", 'updated_date': new Date() }).then(() => {
                        // console.log("user added");
                    })
                    db.collection("loan_logs").add({ 'name': "Done", 'type': 'Loan Disbursement', 'message': `Loan disbursed successfully`, 'updated_date': new Date(), 'phone': params.id }).then(() => {
                        console.log("data added");
                    })
                    notifyApp(msg = "Congratulations Your Loan is disbursed  successfully");
                    Swal.fire('Updated!', '', 'success')
                }
            })

        } else {
            db.collection("loan").doc(uid).update({ 'loan_disbursement_status': parseInt(e.target.value), 'error_message': "", 'updated_date': new Date() }).then(() => {
                // console.log("user added");
            })
        }
    }

    const holdApplication = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You can revert this later!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Hold it!'
        }).then((result) => {
            if (result.isConfirmed) {
                db.collection("loan").doc(uid).update({ 'status': 4, 'custom_msg_two': "Application is on hold!", 'error_message': "Your loan application is temporarily on hold due to the unavailability of funds. Please rest assured that your application remains a priority, and we anticipate processing it within the next 90 days once the necessary funds become available.\n\nWe genuinely value your patience during this period. Thank you for your interest in our loan scheme", 'updated_date': new Date() }).then(() => {
                    // console.log("user added");
                })
                db.collection("loan_logs").add({ 'name': "Hold", 'type': 'Loan Track', 'message': 'Application is on hold', 'updated_date': new Date(), 'phone': params.id }).then(() => {
                    console.log("data added");
                })
            }
        })

    }


    const releaseApplication = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Release it!'
        }).then((result) => {
            if (result.isConfirmed) {
                db.collection("loan").doc(uid).update({ 'status': 0, 'error_message': "Application Released", 'updated_date': new Date() }).then(() => {
                    // console.log("user added");
                })
                db.collection("loan_logs").add({ 'name': "Released", 'type': 'Loan Track', 'message': 'Application is Released', 'updated_date': new Date(), 'phone': params.id }).then(() => {
                    console.log("data added");
                })
            }
        })
    }


    const handleChange7 = async (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
        // alert(e.target.name)
        setName(e.target.name)
        if (e.target.value == "2") {
            const { value: text } = await Swal.fire({
                input: 'textarea',
                inputLabel: 'Message',
                inputPlaceholder: 'Type your message here...',
                inputAttributes: {
                    'aria-label': 'Type your message here'
                },
                showCancelButton: true
            })
            // Swal.fire(name)
            db.collection("loan").doc(uid).update({ 'status': parseInt(e.target.value), 'error_message': text, 'updated_date': new Date(), 'loan_disbursement_date': new Date() }).then(() => {
                // console.log("user added");
            })
            db.collection("loan_logs").add({ 'name': "Rejected", 'type': 'Loan Track', 'message': `Loan Track Not Completed : ${text}`, 'updated_date': new Date(), 'phone': params.id }).then(() => {
                console.log("data added");
            })
        } else if (e.target.value == "1") {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, update it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    db.collection("loan").doc(uid).update({ 'status': parseInt(e.target.value), 'error_message': "", 'updated_date': new Date(), 'loan_disbursement_date': new Date() }).then(() => {
                        // console.log("user added");
                    })
                    db.collection("loan_logs").add({ 'name': "Done", 'type': 'Loan Track', 'message': 'Loan Track Completed', 'updated_date': new Date(), 'phone': params.id }).then(() => {
                        console.log("data added");
                    })
                    Swal.fire('Updated!', '', 'success')
                }
            })

        } else {
            db.collection("loan").doc(uid).update({ 'status': parseInt(e.target.value), 'error_message': "", 'updated_date': new Date(), 'loan_disbursement_date': new Date() }).then(() => {
                // console.log("user added");
            })
        }
    }



    // const handleChange8 = async (e) => {
    //     const { value: text } = await Swal.fire({
    //         input: 'textarea',
    //         inputLabel: 'Message',
    //         inputPlaceholder: 'Type your message here...',
    //         inputAttributes: {
    //             'aria-label': 'Type your message here'
    //         },
    //         showCancelButton: true
    //     });

    //     const newRemark = {
    //         text: text,
    //         updated_date: new Date()
    //     };

    //     // Check if 'text' is not empty (length greater than 0)
    //     if (text.trim() !== '') {
    //         // Perform your action here when text is not empty
    //         // console.log('Text is not empty:', text);
    //         db.collection("loan_extra_data").doc(uid).update({ 'remark': arrayUnion(newRemark) }).then(() => {
    //             console.log("added");
    //         })
    //         // Replace the 'console.log' with the action you want to take.
    //     } else {
    //         // Handle the case when 'text' is empty
    //         console.log('Text is empty');
    //         // You can also provide feedback to the user or perform a different action here.
    //     }
    // }


    // const finalReject = async () => {

    //     const { value: formValues } = await Swal.fire({
    //         title: 'Update Details',
    //         html:
    //             // '<input id="swal-input" class="swal2-input" style="width: 80%;" placeholder="Title">' +
    //             '<select name="cars" id="types" style="width: 80%;" class="swal2-input"><option value="">Select Reason</option><option value="Financial condition not stable">Financial condition not stable</option> <option value="Existing loan">Existing loan</option> <option value="Low level of prepardeness">Low level of prepardeness</option> <option value="Incomplete documents">Incomplete documents</option> <option value="Incomplete images/videos">Incomplete images/videos</option> <option value="Others">Others</option></select>' +
    //             '<textarea id="swal-textarea" class="swal2-textarea" style="width: 80%;" placeholder="Type your description here...">',

    //         showCancelButton: true,
    //         confirmButtonText: 'Save',


    //         preConfirm: () => {
    //             // const inputField = document.getElementById('swal-input').value;
    //             const textareaField = document.getElementById('swal-textarea').value;
    //             const selectFiled = document.getElementById('types').value;

    //             if (selectFiled == "Financial condition not stable") {
    //                 alert("okay")
    //             }

    //             if (!selectFiled || !textareaField) {
    //                 Swal.showValidationMessage('Both fields are required');
    //             }

    //             return [textareaField, selectFiled];
    //         }

    //     });




    //     // if (formValues) {
    //     //     Swal.fire(JSON.stringify(formValues))
    //     // }

    //     const newRemark = {
    //         title: formValues[1],
    //         text: formValues[0],
    //         updated_date: new Date(),
    //         email: email,
    //         images: imgUrls,
    //     };

    //     // // Check if 'text' is not empty (length greater than 0)
    //     if (formValues[0].trim() !== '' && formValues[1].trim() !== '') {
    //         console.log("callinggggggggg")
    //         const docRef = db.collection("loan_extra_data").doc(uid);

    //         // Use .get() to check if the document exists
    //         docRef.get()
    //             .then((docSnapshot) => {
    //                 if (docSnapshot.exists) {
    //                     // Document exists, update it
    //                     docRef.update({
    //                         remark: arrayUnion(newRemark)
    //                     })
    //                         .then(() => {
    //                             console.log("Document updated");
    //                             setSelectedFiles([]);
    //                             setImageUrls([]);
    //                             setImgUrls([])
    //                             setFileKey(fileKey + 1);
    //                         })
    //                         .catch((error) => {
    //                             console.error("Error updating document: ", error);
    //                         });
    //                 } else {
    //                     // Document doesn't exist, create it
    //                     docRef.set({
    //                         remark: [newRemark],
    //                         phone: params.id,
    //                         image: imgUrls,
    //                         email: email,
    //                     })
    //                         .then(() => {
    //                             console.log("Document created");
    //                             setSelectedFiles([]);
    //                             setImageUrls([]);
    //                             setImgUrls([])
    //                             setFileKey(fileKey + 1);
    //                         })
    //                         .catch((error) => {
    //                             console.error("Error creating document: ", error);
    //                         });
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.error("Error checking document existence: ", error);
    //             });
    //         setLoading(false)
    //     } else {
    //         console.log("Text is empty. No update performed.");
    //     }
    // }

    // const handleChange111 = async (e) => {
    //     // alert(e.target.value);
    //     if (e.target.value === "Existing loan") {
    //         const { value: text } = await Swal.fire({
    //             input: "textarea",
    //             inputLabel: "Message",
    //             inputPlaceholder: "Type your message here...",
    //             inputAttributes: {
    //                 "aria-label": "Type your message here"
    //             },
    //             showCancelButton: true
    //         });
    //         if (text) {
    //             Swal.fire(text);
    //         }
    //     }
    // }
    const [rejectDropdown, setRejectDropdown] = useState('');
    const handleChange111 = async (e) => {
        setRejectDropdown(e.target.value);
        if (e.target.value === "Financial condition not stable") {
            const { value: text } = await Swal.fire({
                input: "textarea",
                inputLabel: "Message",
                inputPlaceholder: "Type your message here...",
                inputValue: "We regret to inform you that your returnable grant (RG) application has been rejected as it did not meet the criteria required for approval. During our assessment, we observed that your current financial situation might potentially affect your ability to repay the RG. We understand that financial circumstances can change, and a substantial increase in your income and financial stability could make your application more favorable for reconsideration. If you have any questions or further clarifications, please contact us.Thank you for your interest in our scheme. We appreciate your efforts and hope to see an improved application in the future.", // Set your predefined value here
                inputAttributes: {
                    "aria-label": "Type your message here"
                },
                showCancelButton: true
            });

            if (text) {
                // Swal.fire(text);
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You can revert this later!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Reject it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        db.collection("loan").doc(uid).update({ 'status': 4, 'custom_msg_two': e.target.value, 'error_message': text, 'updated_date': new Date() }).then(() => {
                            // console.log("user added");
                        })
                        db.collection("loan_logs").add({ 'name': "Reject", 'type': 'Loan Track', 'message': 'Application is Rejected', 'updated_date': new Date(), 'phone': params.id }).then(() => {
                            console.log("data added");
                        })
                    }
                })
            }

        } else if (e.target.value === "Existing loan") {
            const { value: text } = await Swal.fire({
                input: "textarea",
                inputLabel: "Message",
                inputPlaceholder: "Type your message here...",
                inputValue: "We regret to inform you that your returnable grant (RG) application has not been rejected as it did not meet the criteria required for approval. The decision was influenced by your existing loan obligation. The existing loan may impact your ability to repay the proposed RG. Understanding your current financial commitments is crucial for both parties to make informed decisions. We understand that financial situations can change, and we would be open to reevaluating your application in the future, should your circumstances improve. If you have any questions or further clarifications, please contact us.Thank you for your interest in our scheme. We appreciate your efforts and hope to see an improved application in the future.", // Set your predefined value here
                inputAttributes: {
                    "aria-label": "Type your message here"
                },
                showCancelButton: true
            });

            if (text) {
                // Swal.fire(text);
                if (text) {
                    // Swal.fire(text);
                    Swal.fire({
                        title: 'Are you sure?',
                        text: "You can revert this later!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Reject it!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            db.collection("loan").doc(uid).update({ 'status': 4, 'custom_msg_two': e.target.value, 'error_message': text, 'updated_date': new Date() }).then(() => {
                                // console.log("user added");
                            })
                            db.collection("loan_logs").add({ 'name': "Reject", 'type': 'Loan Track', 'message': 'Application is Rejected', 'updated_date': new Date(), 'phone': params.id }).then(() => {
                                console.log("data added");
                            })
                        }
                    })
                }
            }

        }
        else if (e.target.value === "Low level of prepardeness") {
            const { value: text } = await Swal.fire({
                input: "textarea",
                inputLabel: "Message",
                inputPlaceholder: "Type your message here...",
                inputValue: "We regret to inform you that your returnable grant (RG) application has not been rejected as it did not meet the criteria required for approval.\nDuring our assessment, we noticed that there was a lower level of preparedness and clarity regarding the utilization of the funds. To enhance your application's chances for reconsideration, we recommend conducting more detailed market research to strengthen your proposal.\nIf you have any questions or further clarifications, please contact us. Thank you for your interest in our scheme. We appreciate your efforts and hope to see an improved application in the future.", // Set your predefined value here
                inputAttributes: {
                    "aria-label": "Type your message here"
                },
                showCancelButton: true
            });

            if (text) {
                // Swal.fire(text);
                if (text) {
                    // Swal.fire(text);
                    Swal.fire({
                        title: 'Are you sure?',
                        text: "You can revert this later!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Reject it!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            db.collection("loan").doc(uid).update({ 'status': 4, 'custom_msg_two': e.target.value, 'error_message': text, 'updated_date': new Date() }).then(() => {
                                // console.log("user added");
                            })
                            db.collection("loan_logs").add({ 'name': "Reject", 'type': 'Loan Track', 'message': 'Application is Rejected', 'updated_date': new Date(), 'phone': params.id }).then(() => {
                                console.log("data added");
                            })
                        }
                    })
                }
            }

        }

        else if (e.target.value === "Not contactable") {
            const { value: text } = await Swal.fire({
                input: "textarea",
                inputLabel: "Message",
                inputPlaceholder: "Type your message here...",
                inputValue: "We regret to inform you that your returnable grant (RG) application has not been rejected as you are not contactable. Our team has made multiple attempts to conatct you  but unfortunately, we have been unable to reach you. If you have any questions or further clarifications, please contact us. Thank you for your interest in our scheme. We appreciate your efforts",
                // Set your predefined value here
                inputAttributes: {
                    "aria-label": "Type your message here"
                },
                showCancelButton: true
            });

            if (text) {
                // Swal.fire(text);
                if (text) {
                    // Swal.fire(text);
                    Swal.fire({
                        title: 'Are you sure?',
                        text: "You can revert this later!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Reject it!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            db.collection("loan").doc(uid).update({ 'status': 4, 'custom_msg_two': e.target.value, 'error_message': text, 'updated_date': new Date() }).then(() => {
                                // console.log("user added");
                            })
                            db.collection("loan_logs").add({ 'name': "Reject", 'type': 'Loan Track', 'message': 'Application is Rejected', 'updated_date': new Date(), 'phone': params.id }).then(() => {
                                console.log("data added");
                            })
                        }
                    })
                }
            }

        }

        else if (e.target.value === "Incomplete documents") {
            const { value: text } = await Swal.fire({
                input: "textarea",
                inputLabel: "Message",
                inputPlaceholder: "Type your message here...",
                inputValue: "We regret to inform you that your returnable grant appication has not rejected due to the absence of required documents. If you have any questions or further clarifications, please contact us. Thank you for your interest in our scheme. We appreciate your efforts hope to see an improved application in the future.", // Set your predefined value here
                inputAttributes: {
                    "aria-label": "Type your message here"
                },
                showCancelButton: true
            });

            if (text) {
                // Swal.fire(text);
                if (text) {
                    // Swal.fire(text);
                    Swal.fire({
                        title: 'Are you sure?',
                        text: "You can revert this later!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Reject it!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            db.collection("loan").doc(uid).update({ 'status': 4, 'custom_msg_two': e.target.value, 'error_message': text, 'updated_date': new Date() }).then(() => {
                                // console.log("user added");
                            })
                            db.collection("loan_logs").add({ 'name': "Reject", 'type': 'Loan Track', 'message': 'Application is Rejected', 'updated_date': new Date(), 'phone': params.id }).then(() => {
                                console.log("data added");
                            })
                        }
                    })
                }
            }

        } else if (e.target.value === "Incomplete images/videos") {
            const { value: text } = await Swal.fire({
                input: "textarea",
                inputLabel: "Message",
                inputPlaceholder: "Type your message here...",
                inputValue: "We regret to inform you that your returnable grant application has not rejected due to the absence of required images/videos. If you have any questions or further clarifications, please contact us. Thank you for your interest in our scheme. We appreciate your efforts hope to see an improved application in the future.",
                // Set your predefined value here
                inputAttributes: {
                    "aria-label": "Type your message here"
                },
                showCancelButton: true
            });

            if (text) {
                // Swal.fire(text);
                if (text) {
                    // Swal.fire(text);
                    Swal.fire({
                        title: 'Are you sure?',
                        text: "You can revert this later!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Reject it!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            db.collection("loan").doc(uid).update({ 'status': 4, 'custom_msg_two': e.target.value, 'error_message': text, 'updated_date': new Date() }).then(() => {
                                // console.log("user added");
                            })
                            db.collection("loan_logs").add({ 'name': "Reject", 'type': 'Loan Track', 'message': 'Application is Rejected', 'updated_date': new Date(), 'phone': params.id }).then(() => {
                                console.log("data added");
                            })
                        }
                    })
                }
            }

        } else if (e.target.value === "Others") {
            const { value: text } = await Swal.fire({
                input: "textarea",
                inputLabel: "Message",
                inputPlaceholder: "Type your message here...",
                inputValue: "", // Set your predefined value here
                inputAttributes: {
                    "aria-label": "Type your message here"
                },
                showCancelButton: true
            });

            if (text) {
                // Swal.fire(text);
                if (text) {
                    // Swal.fire(text);
                    Swal.fire({
                        title: 'Are you sure?',
                        text: "You can revert this later!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Reject it!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            db.collection("loan").doc(uid).update({ 'status': 4, 'custom_msg_two': e.target.value, 'error_message': text, 'updated_date': new Date() }).then(() => {
                                // console.log("user added");
                            })
                            db.collection("loan_logs").add({ 'name': "Reject", 'type': 'Loan Track', 'message': 'Application is Rejected', 'updated_date': new Date(), 'phone': params.id }).then(() => {
                                console.log("data added");
                            })
                        }
                    })
                }
            }

        } else {

        }
    };



    const finalReject = async () => {
        // const { value: formValues } = await Swal.fire({
        //     title: 'Update Details',
        //     html:
        //         '<select name="cars" id="types" style="width: 80%;" class="swal2-input">' +
        //         '<option value="">Select Reason</option>' +
        //         '<option value="Financial condition not stable">Financial condition not stable</option>' +
        //         '<option value="Existing loan">Existing loan</option>' +
        //         '<option value="Low level of prepardeness">Low level of prepardeness</option>' +
        //         '<option value="Incomplete documents">Incomplete documents</option>' +
        //         '<option value="Incomplete images/videos">Incomplete images/videos</option>' +
        //         '<option value="Others">Others</option>' +
        //         '</select>' +
        //         '<textarea id="swal-textarea" class="swal2-textarea" style="width: 80%;" placeholder="Type your description here..."></textarea>',

        //     showCancelButton: true,
        //     confirmButtonText: 'Save',

        //     preConfirm: () => {
        //         const selectField = document.getElementById('types');

        //         if (!selectedValue) {
        //             Swal.showValidationMessage('This fields is required');
        //             return false; // Prevent closing the modal if validation fails
        //         }else{
        //             rejectMessage();
        //         }

        //         return [selectedValue];
        //     }
        // });
    };

    // const rejectMessage = async () => {
    //     const { value: text } = await Swal.fire({
    //         input: "textarea",
    //         inputLabel: "Message",
    //         inputPlaceholder: "Type your message here...",
    //         inputAttributes: {
    //             "aria-label": "Type your message here"
    //         },
    //         showCancelButton: true
    //     });
    //     if (text) {
    //         Swal.fire(text);
    //     }
    // }



    const handleChange8 = async () => {
        // const { value: text } = await Swal.fire({
        //     input: 'textarea',
        //     inputLabel: 'Message',
        //     inputPlaceholder: 'Type your message here...',
        //     inputAttributes: {
        //         'aria-label': 'Type your message here'
        //     },
        //     showCancelButton: true,
        //     confirmButtonText: 'Save',
        // });

        // const { value: text, value: inputField } = await Swal.fire({
        const { value: formValues } = await Swal.fire({
            title: 'Update Details',
            html:
                // '<input id="swal-input" class="swal2-input" style="width: 80%;" placeholder="Title">' +
                '<select name="cars" id="types" style="width: 80%;" class="swal2-input"><option value="">Select Feedback</option><option value="Documentation check feedback">Documentation check feedback</option> <option value="Video verification feedback">Video verification feedback</option> <option value="Physical verification feedback">Physical verification feedback</option> <option value="Penny check verification feedback">Penny check verification feedback</option> <option value="Bank verification check feedback">Bank verification check feedback</option> <option value="Any other feedback">Any other feedback</option> <option value="Overall feedback  ">Overall feedback  </option> </select>' +
                '<textarea id="swal-textarea" class="swal2-textarea" style="width: 80%;" placeholder="Type your remark here...">',

            showCancelButton: true,
            confirmButtonText: 'Save',
            preConfirm: () => {
                // const inputField = document.getElementById('swal-input').value;
                const textareaField = document.getElementById('swal-textarea').value;
                const selectFiled = document.getElementById('types').value;

                if (!selectFiled || !textareaField) {
                    Swal.showValidationMessage('Both fields are required');
                }

                return [textareaField, selectFiled];
            }
        });




        // if (formValues) {
        //     Swal.fire(JSON.stringify(formValues))
        // }

        const newRemark = {
            title: formValues[1],
            text: formValues[0],
            updated_date: new Date(),
            email: email,
            images: imgUrls,
        };

        // // Check if 'text' is not empty (length greater than 0)
        if (formValues[0].trim() !== '' && formValues[1].trim() !== '') {
            console.log("callinggggggggg")
            const docRef = db.collection("loan_extra_data").doc(uid);

            // Use .get() to check if the document exists
            docRef.get()
                .then((docSnapshot) => {
                    if (docSnapshot.exists) {
                        // Document exists, update it
                        docRef.update({
                            remark: arrayUnion(newRemark)
                        })
                            .then(() => {
                                console.log("Document updated");
                                setSelectedFiles([]);
                                setImageUrls([]);
                                setImgUrls([])
                                setFileKey(fileKey + 1);
                            })
                            .catch((error) => {
                                console.error("Error updating document: ", error);
                            });
                    } else {
                        // Document doesn't exist, create it
                        docRef.set({
                            remark: [newRemark],
                            phone: params.id,
                            image: imgUrls,
                            email: email,
                        })
                            .then(() => {
                                console.log("Document created");
                                setSelectedFiles([]);
                                setImageUrls([]);
                                setImgUrls([])
                                setFileKey(fileKey + 1);
                            })
                            .catch((error) => {
                                console.error("Error creating document: ", error);
                            });
                    }
                })
                .catch((error) => {
                    console.error("Error checking document existence: ", error);
                });
            setLoading(false)
        } else {
            console.log("Text is empty. No update performed.");
        }
    }

    const [progress, setProgress] = useState(0);
    const [selectedImages, setSelectedImages] = useState([]);

    const onSelectFileUpload = (e) => {
        e.preventDefault();
        const file = e.target[0].files[0];
        uploadFiles(file);

    }

    const uploadFiles = (files) => {
        // const promises = []
        // files.map((file) => {
        //     console.log('loop');

        //     const sotrageRef = ref(storage, `files/${file.name}`);

        //     const uploadTask = uploadBytesResumable(sotrageRef, file);
        //     promises.push(uploadTask)
        //     uploadTask.on(
        //         "state_changed",
        //         (snapshot) => {
        //             const prog = Math.round(
        //                 (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        //             );
        //             setProgress(prog);
        //         },
        //         (error) => console.log(error),
        //         async () => {
        //             await getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
        //                 setURLs(prevState => [...prevState, downloadURLs])
        //                 console.log("File available at", downloadURLs);
        //             });
        //         }
        //     );


        // })
        // Promise.all(promises)
        //     .then(() => alert('All images uploaded'))
        //     .then(err => console.log(err))

    };

    const [imageUrls, setImageUrls] = useState([]);
    const [progresspercent, setProgresspercent] = useState(0);

    // const handleFileUpload = (e) => {
    //     const files = e.target.files;
    //     uploadImages(files);
    // };

    // const uploadImages = (files) => {
    //     const uploadPromises = [];

    //     for (const file of files) {

    //         const storageRef = ref(storage, `image_loans/${file.name}`);
    //         const uploadTask = uploadBytesResumable(storageRef, file);

    //         uploadTask.on("state_changed",
    //             (snapshot) => {
    //                 // Handle upload progress if needed
    //                 const progress =
    //                     Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
    //                 setProgresspercent(progress);
    //             }, (error) => {
    //                 console.error('Error uploading image:', error);
    //             }, () => {
    //                 // When the upload is complete, get the download URL
    //                 getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
    //                     setImageUrls((prevUrls) => [...prevUrls, downloadURL]);
    //                     console.log('Image URL:', downloadURL);
    //                 });
    //             });

    //         uploadPromises.push(uploadTask);
    //     }

    //     // Optionally, you can do something after all uploads are complete
    //     Promise.all(uploadPromises)
    //         .then(() => {
    //             console.log('All uploads completed');
    //         })
    //         .catch((error) => {
    //             console.error('Error during uploads:', error);
    //         });
    // };

    // console.log(selectedImages);
    // console.log(URLs);
    // const handleSubmit = () => { uploadFiles(images); }


    const handleRepayment = async () => {

        db.collection("repayment_model").doc(personalData.loan_id).set({ 'all_payment_done': repay.all_payment_done, 'loan_amount': personalData.loan_amount, 'loan_id': personalData.loan_id, 'loan_disbursement_date': new Date(), 'emi_model': [{ 'emi_date_1': repay.emi_date_1, 'amount': repay.amount_1, 'payment_link': repay.payment_link_1, 'paid_on': repay.paid_on_1, 'status': repay.status_1 }, { 'emi_date_2': repay.emi_date_2, 'amount': repay.amount_2, 'payment_link': repay.payment_link_2, 'paid_on': repay.paid_on_2, 'status': repay.status_2 }, { 'emi_date_3': repay.emi_date_3, 'amount': repay.amount_3, 'payment_link': repay.payment_link_3, 'paid_on': repay.paid_on_3, 'status': repay.status_3 }], 'updated_date': new Date() }).then(() => {
            console.log("repayment_model");
        })
        // alert("pp")

    }



    const handleChange32 = async (e, msg) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
        if (e.target.value == "1") {
            // db.collection("business_card_request").doc(uid).update({ 'status': "1", 'updated_date': new Date() }).then(() => {
            //     console.log("user added");
            // })
            notifyApp(msg = 'Hi our executive tried getting in touch with you regarding loan request. Kindly call us back at 7428790444 ');
        } else {
            // db.collection("business_card_request").doc(uid).update({ 'status': "0", 'updated_date': new Date() }).then(() => {
            //     console.log("user added");
            // })
        }
    }

    // const Confirm = (id) => {
    //     db.collection("orderd_consumables_products").doc(id).update({ 'status': "Confirmed", 'updated_date': new Date() }).then(() => {
    //         console.log("user added");
    //     })
    // }

    // const message = async () => {
    //     alert("ok")
    //     const { value: text } = await Swal.fire({
    //         input: 'textarea',
    //         inputLabel: 'Message',
    //         inputPlaceholder: 'Type your message here...',
    //         inputAttributes: {
    //             'aria-label': 'Type your message here'
    //         },
    //         showCancelButton: true
    //     })

    //     if (text) {
    //         Swal.fire(text)
    //     }
    // }

    // const handleDeleteRemark = async (data) => {
    //     console.log(data);

    //     const indexToRemove = 0; // Replace with the index you want to remove

    //     const removedItem = data.splice(indexToRemove, 1);

    //     console.log('Removed Item:', removedItem);
    //     console.log('Updated Data:', data);

    //     try {
    //         await db.collection("loan_extra_data").doc("gE4fKRh7DL1ZPw4AwWKe").update({
    //             remark: db.FieldValue.arrayRemove(data), // Wrap the modified data in an array
    //         });

    //         // Update your local state or trigger a re-fetch of the data
    //         // You can use setState or useEffect to refresh your component
    //     } catch (error) {
    //         console.error('Error deleting data:', error);
    //     }

    //     // Now, the element at the specified index is removed from the 'data' array.
    // };

    // const handleDeleteRemark = async (data) => {
    //     console.log(data);

    //     const indexToRemove = 0; // Replace with the index you want to remove
    //     const removedItem = data[indexToRemove];

    //     console.log('Removed Item:', removedItem);
    //     console.log('Updated Data:', data);

    //     // try {
    //     //     await db.collection("loan_extra_data").doc("gE4fKRh7DL1ZPw4AwWKe").update({
    //     //         remark: arrayRemove(removedItem), // Remove the specific element
    //     //     });

    //     //     // Update your local state or trigger a re-fetch of the data
    //     //     // You can use setState or useEffect to refresh your component
    //     // } catch (error) {
    //     //     console.error('Error deleting data:', error);
    //     // }
    // };

    const [indexToRemove, setIndexToRemove] = useState(0);

    const getID = (id, index, data) => {
        // setIndexToRemove(index)
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteRemark(id, index, data)
            }
        })


    }

    const handleDeleteRemark = async (id, index, data) => {
        console.log(data);
        console.log(id);
        console.log(index);

        // const indexToRemove = 0; // Replace with the index you want to remove

        // Get the Firestore document reference
        const docRef = db.collection("loan_extra_data").doc(id);

        try {
            // Fetch the current data from Firestore
            const doc = await docRef.get();
            const existingData = doc.data();

            // Remove the specific element from the 'remark' array
            existingData.remark.splice(index, 1);

            // Update the modified data back to Firestore
            await docRef.update({ remark: existingData.remark });

            // Update your local state or trigger a re-fetch of the data
            // You can use setState or useEffect to refresh your component

        } catch (error) {
            console.error('Error deleting data:', error);
        }
    };


    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleFileSelect = (e) => {
        const files = e.target.files;
        setSelectedFiles(files);
        // Create an array of URLs for image previews
        const previewUrls = Array.from(files).map(file => URL.createObjectURL(file));
        setImageUrls(previewUrls);
    };

    const handleUpload = () => {
        uploadImages(selectedFiles);
    };

    const [imgUrls, setImgUrls] = useState([]);
    const [loading, setLoading] = useState(false);


    const uploadImages = (files) => {
        if (files.length === 0) {
            handleChange8();
        } else {

            setLoading(true);
            const uploadPromises = [];

            for (const file of files) {

                const storageRef = ref(storage, `image_loans/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file);

                uploadTask.on("state_changed",
                    (snapshot) => {
                        // Handle upload progress if needed
                        const progress =
                            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        setProgresspercent(progress);
                    }, (error) => {
                        console.error('Error uploading image:', error);
                    }, () => {
                        // When the upload is complete, get the download URL
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            // setImageUrls((prevUrls) => [...prevUrls, downloadURL]);
                            imgUrls.push(downloadURL)
                            console.log('Image URL:', downloadURL);
                        });
                    });

                uploadPromises.push(uploadTask);
            }

            // Optionally, you can do something after all uploads are complete
            Promise.all(uploadPromises)
                .then(() => {
                    console.log('All uploads completed');
                    handleChange8();

                })
                .catch((error) => {
                    console.error('Error during uploads:', error);
                });
        }



    };

    return (
        <>
            {/* <div>
                <input type="file" multiple onChange={handleFileSelect} />
                <button onClick={handleUpload}>Upload</button>
                <div>
                    <h2>Image Previews:</h2>
                    {imageUrls.length > 0 && (
                        <div style={{ display: 'flex' }}>
                            {imageUrls.map((url, index) => (
                                <div key={index} style={{ marginRight: '10px' }}>
                                    <img src={url} alt={`Image Preview ${index}`} width="50" height="50" />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div> */}
            {/* <div>
                <input type="file" multiple onChange={handleFileSelect} />
                <button onClick={handleUpload}>Upload</button>
                <div>
                    <h2>Uploaded Image URLs:</h2>
                    <ul>
                        {imageUrls.map((url, index) => (
                            <li key={index}>
                                <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div> */}
            {/* <div>
                <input type="file" multiple onChange={handleFileUpload} />
                <div>
                    <h2>Uploaded Image URLs:</h2>
                    <ul>
                        {imageUrls.map((url, index) => (
                            <li key={index}>
                                <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div> */}
            {isLoading ? <div>
                <img src="/images/loader.gif" alt="Popup Image" class="center" style={{
                    display: "block",
                    marginRight: "auto",
                    position: "absolute",
                    height: "200px",
                    width: "200px",
                    top: "40%",
                    left: "50%",
                    marginLeft: "-96px",
                    marginTop: "-50px"
                }} />
            </div> :
                <div class="container-fluid my-3 py-3">
                    <div class="row">

                        {/* <h1>-- {typeof slots?.selected_time == 'undefined' ? "undefined" : "found"}</h1> */}
                        <div class="col-lg-12 mt-lg-0 mt-4">

                            <div class="card card-body" id="profile">
                                <div class="row justify-content-center align-items-center">
                                    <div class="col-sm-auto col-4">
                                        <div class="avatar avatar-xl position-relative">
                                            <img src={personalData.applicant_photo} alt="bruce" class="w-100 h-100 rounded-circle shadow-sm" />
                                        </div>
                                    </div>
                                    <div class="col-sm-auto col-8 my-auto">
                                        <div class="h-100">
                                            <h5 class="mb-1 font-weight-bolder">
                                                {personalData.applicant_name}
                                            </h5>
                                            {personalData.status === 0 ? <p onClick={() => alert("Loan is not disbursed yet")} class="" style={{ color: "red", fontSize: "10px", fontWeight: "600" }}>
                                                Loan ID : {personalData.loan_id}
                                            </p> :
                                                <Link to={`/emi_details/${personalData.loan_id}`}><p class="" style={{ color: "red", fontSize: "10px", fontWeight: "600" }}>
                                                    Loan ID : {personalData.loan_id}
                                                </p></Link>}

                                        </div>

                                    </div>
                                    <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
                                        <label class="form-check-label mb-0">
                                            {/* <small id="profileVisibility">
                                            Switch to invisible
                                        </small> */}
                                        </label>
                                        {/* <div class="form-check form-switch ms-2 my-auto">
                                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault23" checked="" onchange="visible()" />
                                        </div> */}
                                    </div>
                                </div>
                            </div>

                            <div class="card mt-4" id="basic-info">
                                <div class="card-header">
                                    <h5>Basic Details</h5>
                                </div>
                                <div class="card-body pt-0">
                                    <div class="row">
                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">Applicant Name</label>
                                                <input type="text" class="form-control" value={personalData.applicant_name} />
                                            </div>
                                        </div>
                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">Phone Number</label>
                                                <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" value={personalData.applicant_phone} />
                                            </div>
                                        </div>

                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">Alt Phone Number</label>
                                                <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" value={personalData.applicant_alternate_phone} />
                                            </div>
                                        </div>

                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">Date of birth</label>
                                                <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" value={personalData.applicant_date_of_birth} />
                                            </div>
                                        </div>

                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">Marital Status</label>
                                                <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" value={personalData.applicant_marital_status} />
                                            </div>
                                        </div>

                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">Father's Name</label>
                                                <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" value={personalData.fathers_name} />
                                            </div>
                                        </div>

                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">Mother's Name</label>
                                                <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" value={personalData.mothers_name} />
                                            </div>
                                        </div>

                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">City</label>
                                                <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" value={personalData.city} />
                                            </div>
                                        </div>

                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">State</label>
                                                <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" value={personalData.state} />
                                            </div>
                                        </div>

                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">Pincode</label>
                                                <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" value={personalData.pincode} />
                                            </div>
                                        </div>

                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">Address</label>
                                                <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" value={personalData.full_address} />
                                            </div>
                                        </div>

                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">Highest Education</label>
                                                <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" value={personalData.highest_education} />
                                            </div>
                                        </div>


                                    </div>





                                </div>
                            </div>

                            <div class="card mt-4" id="education-details">
                                <div class="card-header">
                                    <h5>Business Details</h5>
                                </div>
                                <div class="card-body pt-0">
                                    <div class="row">
                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">Business Type</label>
                                                <input type="text" class="form-control" placeholder="Alec" value={personalData.business_type} />
                                            </div>
                                        </div>
                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">Business Name</label>
                                                <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" value={personalData.parlour_freelancer_name} />
                                            </div>
                                        </div>

                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">Business Address</label>
                                                <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" value={personalData.parlour_address} />
                                            </div>
                                        </div>

                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">Ownership Type</label>
                                                <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" value={personalData.ownership_type} />
                                            </div>
                                        </div>

                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">Working Since</label>
                                                <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" value={personalData.working_since} />
                                            </div>
                                        </div>

                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">Monthly Income</label>
                                                <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" value={personalData.monthly_since} />
                                            </div>
                                        </div>


                                    </div>


                                </div>
                            </div>

                            <div class="card mt-4" id="bank-details">
                                <div class="card-header">
                                    <h5>Bank Details</h5>
                                </div>
                                <div class="card-body pt-0">
                                    <div class="row">
                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">Bank Account Number</label>
                                                <input type="text" class="form-control" value={personalData.bank_account_number} />
                                            </div>
                                        </div>
                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">Bank Name</label>
                                                <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" value={personalData.bank_name} />
                                            </div>
                                        </div>

                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">IFSC CODE</label>
                                                <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" value={personalData.ifsc_code} />
                                            </div>
                                        </div>

                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">UPI ID (Optional)</label>
                                                <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" value={personalData.upi_id} />
                                            </div>
                                        </div>

                                    </div>





                                </div>
                            </div>

                            <div class="card mt-4" id="purpose-of-grant">
                                <div class="card-header">
                                    <h5>Loan Details</h5>
                                </div>
                                <div class="card-body pt-0">
                                    <div class="row">
                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">Loan Amount</label>
                                                <input type="text" class="form-control" value={personalData.loan_amount} />
                                            </div>
                                        </div>
                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">Have you taken any loan in the past?</label>
                                                <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" value={personalData.have_you_taken_loan_in_past} />
                                            </div>
                                        </div>
                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">Why do you need the grant</label>
                                                <input type="text" class="form-control" value={personalData.needGrant} />
                                            </div>
                                        </div>

                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">Specify</label>
                                                <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" value={personalData.purposeOfReason} />
                                            </div>
                                        </div>

                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">Estimated</label>
                                                <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" value={personalData.reasonEstimated} />
                                            </div>
                                        </div>


                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">Repayment Model</label>
                                                <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" value={personalData.repaymentModel} />
                                            </div>
                                        </div>

                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">How did you get to know about this loan</label>
                                                <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" value={personalData.how_did_you_know_about_scheme} />
                                            </div>
                                        </div>

                                    </div>





                                </div>
                            </div>

                            <div class="card mt-4" id="documents">
                                <div class="card-header">
                                    <h5>Documents</h5>
                                </div>
                                <div class="card-body pt-0">
                                    <div class="row">
                                        <div class="col-6 pt-3 p-4">
                                            <div class="input-group-outline pt-3" style={{ display: "flex", textAlign: "center" }}>
                                                <label class="form-label pb-2" style={{ display: "flex", justifyContent: "center" }}>Aadhaar Front</label>
                                                <div class="position-relative">
                                                    <img src={personalData.aadhar_front} alt="bruce" class="w-50 shadow-sm" />
                                                    <br />
                                                    <a href={personalData.aadhar_front} download target="_blank"><span class="badge badge-success ms-auto mb-auto">Download</span></a>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="col-6 pt-3 p-4">
                                            <div class="input-group-outline pt-3" style={{ display: "flex", textAlign: "center" }}>
                                                <label class="form-label pb-2" style={{ display: "flex", justifyContent: "center" }}>Aadhaar Back</label>
                                                <div class="position-relative">
                                                    <img src={personalData.aadhar_back} alt="bruce" class="w-50 shadow-sm" />
                                                    <br />
                                                    <a href={personalData.aadhar_back} download target="_blank"><span class="badge badge-success ms-auto mb-auto">Download</span></a>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-6 pt-3 p-4">
                                            <div class="input-group-outline pt-3" style={{ display: "flex", textAlign: "center" }}>
                                                <label class="form-label pb-2" style={{ display: "flex", justifyContent: "center" }}>PAN Card</label>
                                                <div class="position-relative">
                                                    <img src={personalData.pan} alt="bruce" class="w-50 shadow-sm" />
                                                    <br />
                                                    <a href={personalData.pan} download target="_blank"><span class="badge badge-success ms-auto mb-auto">Download</span></a>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-6 pt-3 p-4" >
                                            <div class="input-group-outline pt-3" style={{ display: "flex", textAlign: "center" }}>
                                                <label class="form-label pb-2" style={{ display: "flex", justifyContent: "center" }}>Bank Passbook</label>
                                                <div class="position-relative">
                                                    <img src={personalData.passbook} alt="bruce" class="w-50 shadow-sm" />
                                                    <br />
                                                    <a href={personalData.passbook} download target="_blank"><span class="badge badge-success ms-auto mb-auto">Download</span></a>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-6 pt-3 p-4" >
                                            <div class="input-group-outline pt-3" style={{ display: "flex", textAlign: "center" }}>
                                                <label class="form-label pb-2" style={{ display: "flex", justifyContent: "center" }}>Bank Statement</label>
                                                {personalData.parlour_photo === "" ? <div class="position-relative">
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1200px-No-Image-Placeholder.svg.png" alt="bruce" class="w-50 shadow-sm" />
                                                    <br />
                                                </div> : <>
                                                    <div class="position-relative">
                                                        <img src={personalData.parlour_photo} alt="bruce" class="w-50 shadow-sm" />
                                                        <br />
                                                        <a href={personalData.parlour_photo} download target="_blank"><span class="badge badge-success ms-auto mb-auto">Download</span></a>
                                                    </div></>}
                                            </div>
                                        </div>

                                        <div class="col-6 pt-3 p-4" >
                                            <div class="input-group-outline pt-3" style={{ display: "flex", textAlign: "center" }}>
                                                <label class="form-label pb-2" style={{ display: "flex", justifyContent: "center" }}>Applicant Photo</label>
                                                {personalData.applicant_photo === "" ? <div class="position-relative">
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1200px-No-Image-Placeholder.svg.png" alt="bruce" class="w-50 shadow-sm" />
                                                    <br />
                                                </div> : <>
                                                    <div class="position-relative">
                                                        <img src={personalData.applicant_photo} alt="bruce" class="w-50 shadow-sm" />
                                                        <br />
                                                        <a href={personalData.applicant_photo} download target="_blank"><span class="badge badge-success ms-auto mb-auto">Download</span></a>
                                                    </div>
                                                </>
                                                }
                                            </div>
                                        </div>


                                        <div class="col-12 pt-3 p-4" >
                                            <div class="input-group-outline pt-3" style={{ display: "flex", textAlign: "start" }}>
                                                <label class="form-label pb-2" style={{ display: "flex", justifyContent: "start" }}>Business Photo & Videos</label>
                                                <div class="position-relative">
                                                    <div style={{ display: 'flex', justifyContent: "end" }}>
                                                        {personalData.business_photos.map((url, index) => (
                                                            <div key={index} style={{ marginRight: '10px' }}>
                                                                <a href={url} download target="_blank"><img src="https://cdn-icons-png.flaticon.com/128/2397/2397714.png" alt={`Image Preview ${index}`} width="80" height="80" /></a>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </div>







                                </div>
                            </div>


                            <div class="card mt-4" id="update-status">
                                {/* <select class="form-control" name="status" onChange={handleChange32} style={{ padding: "7px", marginLeft: "1rem", marginTop: "1rem", marginBottom: "1rem", width: "9rem", backgroundColor: data.status == 1 ? "#28a745" : "transparent" }} disabled={data.status == 1 ? true : false} >
                                    <option selected={data.status === 0 ? true : false} value="0">Select Call BAck Request</option>
                                    <option selected={data.status === 1 ? true : false} value="1">Request</option>
                                </select> */}
                                <select class="form-control" onChange={handleChange111} style={{ padding: "7px", marginLeft: "1rem", marginTop: "1rem", marginBottom: "1rem", width: "10rem", backgroundColor: data.status == 1 ? "#28a745" : "transparent" }}>
                                    <option value="">Reject Application</option>
                                    <option value="Financial condition not stable">Financial condition not stable</option>
                                    <option value="Existing loan">Existing loan</option>
                                    <option value="Not contactable">Not contactable</option>
                                    <option value="Low level of prepardeness">Low level of prepardeness</option>
                                    <option value="Incomplete documents">Incomplete documents</option>
                                    <option value="Incomplete images/videos">Incomplete images/videos</option>
                                    <option value="Others">Others</option>
                                </select>
                                <div class="card-header d-flex">
                                    <h5 class="mb-0">Update Loan Status</h5>
                                    {personalData.status === 4 ? <><span class="badge badge-secondary ms-auto mb-auto mr-3">Disabled</span></> : <><span class="badge badge-success ms-auto mb-auto mr-3">Enabled</span></>}

                                    {personalData.status === 4 ? <><span class="badge badge-danger mb-auto" onClick={() => releaseApplication()} style={{ color: "white", cursor: "pointer" }}>Release Application</span></> : <><span class="badge badge-danger mb-auto" onClick={() => holdApplication()} style={{ color: "white", cursor: "pointer" }}>Hold Application</span></>}
                                    {/* <span class="badge badge-danger mb-auto ml-4" onClick={() => finalReject()} style={{ color: "white", cursor: "pointer" }}>Reject Application</span> */}


                                </div>
                                {/* <p>Hold</p> : <p>Not Hold</p>} */}
                                {personalData.status === 4 ? <>

                                    <div class="p-4">
                                        {/* <h4>Application is on Hold!</h4> */}
                                        <h4>{personalData.custom_msg_two}</h4>
                                    </div>

                                </>
                                    : <>
                                        <div class="card-body">
                                            <div class="d-flex">
                                                <p class="my-auto">Document Verification</p>
                                                <p class="text-secondary text-sm ms-auto my-auto me-3"></p>

                                                <select class="form-control" id="document_status" name="document_status" onChange={handleChange1} value={submit.document_status} style={{ padding: "8px", width: "20%", backgroundColor: personalData.document_status === 1 ? "#28a745" : personalData.document_status === 2 ? "#f26d6282" : "transparent" }} disabled={personalData.document_status == 1 ? true : false}>
                                                    <option value="" disabled>Change Status</option>
                                                    <option selected={personalData.document_status == 0 ? true : false} value="0">Pending</option>
                                                    <option selected={personalData.document_status == 1 ? true : false} value="1">Done</option>
                                                    <option selected={personalData.document_status == 2 ? true : false} value="2">Reject</option>
                                                </select>
                                            </div>
                                            <hr class="horizontal dark" />
                                            <div class="d-flex">
                                                <p class="my-auto">Video Call Verification</p>

                                                {/* {personalData.verification_status == 1 ? <></> : <><p class="text-secondary text-sm ms-auto my-auto me-3"><span style={{ color: "orange", fontSize: "13px", fontWeight: "700", cursor: "pointer" }} onClick={() => changeStatus()}>Choose Time Slot</span></p></>} */}
                                                {personalData.verification_status == 1 ? <><p class="text-secondary text-sm ms-auto my-auto me-3"></p></> : <>{personalData.document_status == 1 ? <><p class="text-secondary text-sm ms-auto my-auto me-3 pt-2"> {typeof slots?.selected_time !== 'undefined' ? <>{slots?.selected_time === "" ? <><p>Allocated Slots: {moment(slots?.selected_date.toDate().toISOString()).format("MM/DD/YY")} :  {slots?.time_slot?.join(', ')}</p></> : <><p style={{ color: "green" }}>Selected Slot : {slots?.selected_time}</p></>}</> : <p>Available Slots:  {slots?.time_slot?.join(', ')}</p>}</p></> : <><p class="text-secondary text-sm ms-auto my-auto me-3"></p></>}</>}
                                                <select class="form-control" id="verification_status" name="verification_status" onChange={handleChange2} value={submit.verification_status} style={{ padding: "8px", width: "20%", backgroundColor: personalData.verification_status === 1 ? "#28a745" : personalData.verification_status === 2 ? "#f26d6282" : "transparent" }} disabled={personalData.verification_status == 1 ? true : personalData.document_status == 1 ? false : true}>
                                                    <option value="" disabled>Change Status</option>
                                                    <option selected={personalData.verification_status == 0 ? true : false} value="0">Pending</option>
                                                    <option selected={personalData.verification_status == 1 ? true : false} value="1">Done</option>
                                                    <option selected={personalData.verification_status == 2 ? true : false} value="2">Reject</option>
                                                    <option value="3">Select Time Slot</option>

                                                </select>
                                            </div>
                                            <hr class="horizontal dark" />
                                            <div class="d-flex">
                                                <p class="my-auto">Physical Verification</p>
                                                <p class="text-secondary text-sm ms-auto my-auto me-3"></p>
                                                <select class="form-control" id="nbfc_status" name="nbfc_status" onChange={handleChange3} value={submit.nbfc_status} style={{ padding: "8px", width: "20%", backgroundColor: personalData.nbfc_status === 1 ? "#28a745" : personalData.nbfc_status === 2 ? "#f26d6282" : "transparent" }} disabled={personalData.nbfc_status == 1 ? true : personalData.verification_status == 1 ? false : true}>
                                                    <option value="" disabled>Change Status</option>
                                                    <option selected={personalData.nbfc_status == 0 ? true : false} value="0">Pending</option>
                                                    <option selected={personalData.nbfc_status == 1 ? true : false} value="1">Done</option>
                                                    <option selected={personalData.nbfc_status == 2 ? true : false} value="2">Reject</option>
                                                </select>
                                            </div>
                                            <hr class="horizontal dark" />
                                            <div class="d-flex">
                                                <p class="my-auto">Penny Check Verification</p>
                                                <p class="text-secondary text-sm ms-auto my-auto me-3"></p>
                                                <select class="form-control" id="penny_check_status" name="penny_check_status" onChange={handleChange4} value={submit.penny_check_status} style={{ padding: "8px", width: "20%", backgroundColor: personalData.penny_check_status === 1 ? "#28a745" : personalData.penny_check_status === 2 ? "#f26d6282" : "transparent" }} disabled={personalData.penny_check_status == 1 ? true : personalData.nbfc_status == 1 ? false : true}>
                                                    <option value="" disabled>Change Status</option>
                                                    <option selected={personalData.penny_check_status == 0 ? true : false} value="0">Pending</option>
                                                    <option selected={personalData.penny_check_status == 1 ? true : false} value="1">Done</option>
                                                    <option selected={personalData.penny_check_status == 2 ? true : false} value="2">Reject</option>
                                                </select>
                                            </div>
                                            <hr class="horizontal dark" />
                                            <div class="d-flex">
                                                <p class="my-auto">Bank Verification</p>
                                                {/* {personalData.godrej_status == 1 ? <><p class="text-secondary text-sm ms-auto my-auto me-3"></p></> : <>{personalData.penny_check_status == 1 ? <><p class="text-secondary text-sm ms-auto my-auto me-3 pt-2"> {slots?.selected_time !== "" ? <p style={{ color: "green" }}>Selected Slot : {slots?.selected_time}</p> : <p>Available Slots:  {slots?.time_slot?.join(', ')}</p>}</p></> : <><p class="text-secondary text-sm ms-auto my-auto me-3"></p></>}</>} */}
                                                {/* {personalData.godrej_status == 1 ? <></> : <>{personalData.penny_check_status == 1 ? <><p class="text-secondary text-sm ms-auto my-auto me-3 pt-2"> {typeof slots?.selected_time !== 'undefined' ? <>{slots?.selected_time === "" ? <><p>Allocated Slots:  {slots?.time_slot?.join(', ')}</p></> : <><p style={{ color: "green" }}>Selected Slot : {slots?.selected_time}</p></>}</> : <p>Available Slots:  {slots?.time_slot?.join(', ')}</p>}</p></> : <><p class="text-secondary text-sm ms-auto my-auto me-3"></p></>}</>} */}
                                                {/* {personalData.godrej_status != 1 ? <><p class="text-secondary text-sm ms-auto my-auto me-3"></p></> : <>{personalData.penny_check_status == 1 ? <><p class="text-secondary text-sm ms-auto my-auto me-3 pt-2"> {typeof slots?.selected_time !== 'undefined' ? <>{slots?.selected_time === "" ? <><p>Allocated Slots:  {slots?.time_slot?.join(', ')}</p></> : <><p style={{ color: "green" }}>Selected Slot : {slots?.selected_time}</p></>}</> : <><p>Available Slots:  {slots?.time_slot?.join(', ')}</p></>}</p></> : <></>}</>} */}
                                                {personalData.godrej_status == 1 ? <><p class="text-secondary text-sm ms-auto my-auto me-3"></p></> : <>{personalData.penny_check_status == 1 ? <><p class="text-secondary text-sm ms-auto my-auto me-3 pt-2"> {typeof slots?.selected_time !== 'undefined' ? <>{slots?.selected_time === "" ? <><p>Allocated Slots: {moment(slots?.selected_date.toDate().toISOString()).format("MM/DD/YY")} :   {slots?.time_slot?.join(', ')}</p></> : <><p style={{ color: "green" }}>Selected Slot : {slots?.selected_time}</p></>}</> : <p>Available Slots:  {slots?.time_slot?.join(', ')}</p>}</p></> : <><p class="text-secondary text-sm ms-auto my-auto me-3"></p></>}</>}
                                                {/* {personalData.godrej_status == 1 ? <><p class="text-secondary text-sm ms-auto my-auto me-3"></p></> : <>{personalData.penny_check_status == 1 ? <p class="text-secondary text-sm ms-auto my-auto me-3"> Selected Slots: {slots?.time_slot?.join(', ')}</p> : <p class="text-secondary text-sm ms-auto my-auto me-3"></p>}</>} */}
                                                <select class="form-control" id="godrej_status" name="godrej_status" onChange={handleChange5} value={submit.godrej_status} style={{ padding: "8px", width: "20%", backgroundColor: personalData.godrej_status === 1 ? "#28a745" : personalData.godrej_status === 2 ? "#f26d6282" : "transparent" }} disabled={personalData.godrej_status == 1 ? true : personalData.penny_check_status == 1 ? false : true}>
                                                    <option value="" disabled>Change Status</option>
                                                    <option selected={personalData.godrej_status == 0 ? true : false} value="0">Pending</option>
                                                    <option selected={personalData.godrej_status == 1 ? true : false} value="1">Done</option>
                                                    <option selected={personalData.godrej_status == 2 ? true : false} value="2">Reject</option>
                                                    <option value="3">Select Time Slot</option>
                                                </select>
                                            </div>
                                            <hr class="horizontal dark" />
                                            <div class="d-flex">
                                                <p class="my-auto">Loan Disbursement</p>
                                                <p class="text-secondary text-sm ms-auto my-auto me-3"></p>
                                                <select class="form-control" id="loan_disbursement_status" name="loan_disbursement_status" onChange={handleChange6} value={submit.loan_disbursement_status} style={{ padding: "8px", width: "20%", backgroundColor: personalData.loan_disbursement_status === 1 ? "#28a745" : personalData.loan_disbursement_status === 2 ? "#f26d6282" : "transparent" }} disabled={personalData.loan_disbursement_status == 1 ? true : personalData.godrej_status == 1 ? false : true}>
                                                    <option value="" disabled>Change Status</option>
                                                    <option selected={personalData.loan_disbursement_status == 0 ? true : false} value="0">Pending</option>
                                                    <option selected={personalData.loan_disbursement_status == 1 ? true : false} value="1">Done</option>
                                                    <option selected={personalData.loan_disbursement_status == 2 ? true : false} value="2">Reject</option>
                                                </select>
                                            </div>

                                            <hr class="horizontal dark" />
                                            <div class="d-flex">
                                                <p class="my-auto">Final Status</p>
                                                <p class="text-secondary text-sm ms-auto my-auto me-3"></p>
                                                {/* <select class="form-control" id="status" name="status" onChange={handleChange7} value={submit.status} style={{ padding: "8px", width: "20%", backgroundColor: personalData.status === 1 ? "#28a745" : personalData.status === 2 ? "#f26d6282" : "transparent" }} disabled={personalData.status == 1 ? true : personalData.loan_disbursement_status == 1 ? false : true}> */}
                                                <select class="form-control" id="status" name="status" onChange={handleChange7} value={submit.status} style={{ padding: "8px", width: "20%", backgroundColor: personalData.status === 1 ? "#28a745" : personalData.status === 2 ? "#f26d6282" : "transparent" }} disabled={personalData.status == 1 ? true : personalData.loan_disbursement_status == 1 ? false : true}>
                                                    <option value="" disabled>Change Status</option>
                                                    <option selected={personalData.status == 0 ? true : false} value="0">Pending</option>
                                                    <option selected={personalData.status == 1 ? true : false} value="1">Done</option>
                                                    <option selected={personalData.status == 2 ? true : false} value="2">Reject</option>
                                                </select>
                                            </div>

                                            <hr class="horizontal dark" />
                                            <div class="d-flex">
                                                <p class="my-auto">Internal Remarks</p>
                                                <p class="text-secondary text-sm ms-auto my-auto me-3"></p>
                                                {/* <select class="form-control" id="status" name="status" onChange={handleChange7} value={submit.status} style={{ padding: "8px", width: "20%", backgroundColor: personalData.status === 1 ? "#28a745" : personalData.status === 2 ? "#f26d6282" : "transparent" }} disabled={personalData.status == 1 ? true : personalData.loan_disbursement_status == 1 ? false : true}> */}
                                                <input type="file" multiple onChange={handleFileSelect} key={fileKey} />

                                                {/* <select class="form-control" id="internal_remarks" name="status" onChange={handleChange8} style={{ padding: "8px", width: "20%" }}>
                                            <option value="">Remarks</option>
                                            <option value="0">Add Remarks</option>
                                        </select> */}
                                                {loading ? <><span>Please Wait......</span></> : <> <button class="btn bg-gradient-danger mb-0 ms-2" type="button" onClick={() => handleUpload()} name="button">Add Remark & Save</button> </>}
                                            </div>

                                            <div class="mt-2">
                                                <p style={{ display: "flex", justifyContent: "end" }}>Selected Image Previews:</p>
                                                {imageUrls.length > 0 && (
                                                    <div style={{ display: 'flex', justifyContent: "end" }}>
                                                        {imageUrls.map((url, index) => (
                                                            <div key={index} style={{ marginRight: '10px' }}>
                                                                <img src={url} alt={`Image Preview ${index}`} width="80" height="80" />
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>

                                            <hr class="horizontal dark" />
                                            {personalData.status === 1 ? <div class="d-flex">
                                                <p class="my-auto">Disbursement Date</p>
                                                <p class="text-secondary text-sm ms-auto my-auto me-3"></p>
                                                <p>{moment(personalData?.loan_disbursement_date?.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p>
                                            </div> : null}
                                        </div>
                                    </>}
                            </div>




                            {/* 
                            <div class="card mt-4" id="notifications">
                                <div class="card-header">
                                    <h5>Notifications</h5>
                                    <p class="text-sm">Choose how you receive notifications. These notification settings apply to the things you’re watching.</p>
                                </div>
                                <div class="card-body pt-0">
                                    <div class="table-responsive fix-width scroll-inner">
                                        <table class="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th class="ps-1" colspan="4">
                                                        <p class="mb-0">Activity</p>
                                                    </th>
                                                    <th class="text-center">
                                                        <p class="mb-0">Email</p>
                                                    </th>
                                                    <th class="text-center">
                                                        <p class="mb-0">Push</p>
                                                    </th>
                                                    <th class="text-center">
                                                        <p class="mb-0">SMS</p>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="ps-1" colspan="4">
                                                        <div class="my-auto">
                                                            <span class="text-dark d-block text-sm">Mentions</span>
                                                            <span class="text-xs font-weight-normal">Notify when another user mentions you in a comment</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
                                                            <input class="form-check-input" checked="" type="checkbox" id="" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
                                                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault12" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
                                                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault13" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="ps-1" colspan="4">
                                                        <div class="my-auto">
                                                            <span class="text-dark d-block text-sm">Comments</span>
                                                            <span class="text-xs font-weight-normal">Notify when another user comments your item.</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
                                                            <input class="form-check-input" checked="" type="checkbox" id="flexSwitchCheckDefault14" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
                                                            <input class="form-check-input" checked="" type="checkbox" id="flexSwitchCheckDefault15" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
                                                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault16" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="ps-1" colspan="4">
                                                        <div class="my-auto">
                                                            <span class="text-dark d-block text-sm">Follows</span>
                                                            <span class="text-xs font-weight-normal">Notify when another user follows you.</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
                                                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault17" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
                                                            <input class="form-check-input" checked="" type="checkbox" id="flexSwitchCheckDefault18" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
                                                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault19" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="ps-1" colspan="4">
                                                        <div class="my-auto">
                                                            <p class="text-sm mb-0">Log in from a new device</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
                                                            <input class="form-check-input" checked="" type="checkbox" id="flexSwitchCheckDefault20" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
                                                            <input class="form-check-input" checked="" type="checkbox" id="flexSwitchCheckDefault21" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
                                                            <input class="form-check-input" checked="" type="checkbox" id="flexSwitchCheckDefault22" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div> */}


                            {/* <div class="card mt-4" id="delete">
                                <div class="card-body">
                                    <div class="d-flex align-items-center mb-sm-0 mb-4">
                                        <div class="w-50">
                                            <h5>Delete File</h5>
                                            <p class="text-sm mb-0">Once you delete your account, there is no going back. Please be certain.</p>
                                        </div>
                                        <div class="w-50 text-end">
                                            <button class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto" type="button" name="button">Deactivate</button>
                                            <button class="btn bg-gradient-danger mb-0 ms-2" type="button" name="button">Delete Account</button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        {/* <p class="pt-2">Last Update Date : {moment(personalData?.updated_date?.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p> */}
                        {/* <p class="pt-2">{personalData.document_status == 2 ? "Document Verification Rejected : " : ""} {personalData.document_status == 2 ? personalData?.error_message : ""}</p>
                        <p class="pt-2">{personalData.verification_status == 2 ? "Video Verification Rejected : " : ""} {personalData.verification_status == 2 ? personalData?.error_message : ""}</p>
                        <p class="pt-2">{personalData.nbfc_status == 2 ? "Physical Verification Rejected : " : ""} {personalData.nbfc_status == 2 ? personalData?.error_message : ""}</p>
                        <p class="pt-2">{personalData.penny_check_status == 2 ? "Penny Verification Rejected : " : ""} {personalData.penny_check_status == 2 ? personalData?.error_message : ""}</p>
                     */}
                    </div>
                </div>
            }

            <div style={{ marginBottom: "4rem" }}>
                <span style={{ marginRight: "4rem", marginLeft: "2rem", color: views === "remarks" ? "#009688" : "black", fontWeight: "700", cursor: "pointer" }} onClick={() => viewR()} >View Remarks</span>
                <span class="" onClick={() => view()} style={{ color: views === "logs" ? "#009688" : "black", fontWeight: "700", cursor: "pointer" }}>View Track Logs</span>
            </div>

            {views === "remarks" ?
                <>
                    <div class="p-2">
                        <div class="experience">
                            {remarks?.length === 0 ? (
                                <p style={{ color: "black" }}>No Remarks added</p>
                            ) : (
                                remarks?.map(({ id, data }, index) => {

                                    // const odata = data?.remark?.map((e, ind) =>({
                                    //     ...remark,
                                    //     originalIndex: ind,
                                    // }));
                                    // Sort the data.remark array in descending order based on updated_date


                                    // Add the originalIndex field to each remark in sortedRemarks
                                    const sortedRemarksWithOriginalIndex = data?.remark?.map((remark, remarkIndex) => ({
                                        ...remark,
                                        originalIndex: remarkIndex,
                                    }));

                                    const sortedRemarks = sortedRemarksWithOriginalIndex?.sort((a, b) => b.updated_date.toDate() - a.updated_date.toDate());

                                    return (
                                        <>
                                            {sortedRemarks?.map((e, i) => {
                                                const textWithLineBreaks = e.text.replace(/\n/g, '<br>')
                                                return (<>
                                                    <div class="item" key={e.id}>
                                                        <h5 class="company-name" style={{ fontSize: "1rem" }}>{e.title}</h5>
                                                        <span style={{ color: "#e91e63" }}>({e.email})</span>
                                                        <div class="job-info">
                                                            <div>{moment(e.updated_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</div>

                                                        </div>
                                                        <div style={{ paddingTop: "1rem" }}>
                                                            <ul class="fa-ul">
                                                                <li><i class="fa-li fa fa-hand-o-right"></i>
                                                                    <div dangerouslySetInnerHTML={{ __html: textWithLineBreaks }} />
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div style={{ marginRight: '10px' }}>
                                                            {e?.images.map((image) => (
                                                                <a href={image} target="_blank">
                                                                    <img style={{ marginRight: "2rem" }} src={image} alt="img" width="50" height="50" />
                                                                </a>
                                                            ))}
                                                        </div>
                                                        {/* <button onClick={() => getID(id, e.originalIndex, data.remark)}>Delete Remark</button> */}
                                                        <img src="/images/icon_delete.png" class="me-3" onClick={() => getID(id, e.originalIndex, data.remark)} style={{ height: "2rem", cursor: "pointer" }} />
                                                        {/* <span style={{ cursor: "pointer" }} onClick={() => getID(id, e.originalIndex, data.remark)} class="badge badge-danger ms-auto mb-auto mr-2"></span> */}
                                                    </div>
                                                </>)
                                            })}
                                        </>
                                    );
                                })
                            )}
                        </div>
                    </div>
                </>
                :
                <>
                    <div class="p-2">
                        <div class="experience">
                            {logs?.map(({ id, data }, index) => (
                                <div class="item" key={index}>
                                    <h5 class="company-name" style={{ fontSize: "1rem" }}>{data.type}</h5>
                                    <div class="job-info">
                                        <div class="title" style={{ color: data.name === "Done" ? null : "red" }}>{data.name}</div>
                                        <div>{moment(data.updated_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</div>
                                    </div>
                                    <div>
                                        <ul class="fa-ul">
                                            <li><i class="fa-li fa fa-hand-o-right"></i>{data.message}</li>
                                        </ul>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            }

            {/* <div class="p-2">
                <div class="experience">
                    {logs?.map(({ id, data }, index) => (
                        <div class="item" key={index}>
                            <h5 class="company-name" style={{ fontSize: "1rem" }}>{data.type}</h5>
                            <div class="job-info">
                                <div class="title" style={{ color: data.name === "Done" ? null : "red" }}>{data.name}</div>
                                <div>{moment(data.updated_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</div>
                            </div>
                            <div>
                                <ul class="fa-ul">
                                    <li><i class="fa-li fa fa-hand-o-right"></i>{data.message}</li>
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div> */}






            <div class="myId modal fade bd-example-modal-lg" id="referParlour" tabindex="-1" role="dialog" aria-labelledby="referParlourTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">

                        <div class="modal-body">
                            <>
                                <div id="small-dialog" class="">
                                    <div class="w3layouts_payment_online_form">
                                        <div className="time-slot-container">
                                            <h2>Choose Time Slots</h2>
                                            <DatePicker
                                                selected={selectedDate}
                                                className="form-control"
                                                onChange={handleDateChange}
                                                dateFormat="MMMM d, yyyy"
                                            />
                                            <ul className="time-slot-list">
                                                {timeSlots.map((slot) => (
                                                    <li
                                                        key={slot}
                                                        className={`time-slot-item ${selectedSlots.includes(slot) ? 'selected' : ''}`}
                                                        onClick={() => handleSlotSelection(slot)}
                                                    >
                                                        {slot}
                                                    </li>
                                                ))}
                                            </ul>
                                            <p>Selected Slots: {selectedSlots.join(', ')}</p>
                                        </div>
                                        <ul class="w3_payment_sendbtns">
                                            <li><input type="reset" data-dismiss="modal" aria-label="Close" value="Cancel" style={{ marginRight: "1rem" }} /></li>
                                            <li></li>
                                            <li><input type="submit" value="Add Slots" onClick={() => timeSlot()} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            </div>

        </>

    );

}

export default LoanDetails;

