import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { read, utils } from 'xlsx';
import Swal from 'sweetalert2';
import db from "./Firebase"; // Make sure you have the Firebase import

function SendCertificateCode() {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [docIds, setDocIds] = useState([]);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            const workbook = read(e.target.result);
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = utils.sheet_to_json(sheet);
            setData(jsonData);
            const phones = jsonData.map(item => item.Phone);
            const ids = jsonData.map(item => item.Regid);
            setPhoneNumbers(phones);
            setDocIds(ids);
            console.log("Phone Numbers:", phones);
            console.log("Doc IDs:", ids);
        };
        reader.readAsArrayBuffer(file);
    };

    const sendWhatsapp = async () => {
        const { value: formValues } = await Swal.fire({
            title: "Multiple inputs",
            html: `
                <input id="swal-input1" class="swal2-input">
                <input id="swal-input2" class="swal2-input">
            `,
            focusConfirm: false,
            preConfirm: () => {
                const input1 = document.getElementById("swal-input1").value;
                const input2 = document.getElementById("swal-input2").value;

                if (!input1 || !input2) {
                    Swal.showValidationMessage("Both fields are required.");
                    return false;
                }

                return [input1, input2];
            }
        });

        if (formValues) {
            const [text1, text2] = formValues;

            setLoading(true);
            setProgress(0);
            const myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer EABMKlZCrATXIBAPOZBZBPqsdfot34fq0FTipZBQf3RiXZAapyCOqMay09HfhSxoB8bd5ZA7RUnLWhd0JkXtACJQ682THiDEMhQhyPOkeui8Cb8P72WPDhihUZBCkHtPLSgsmuZA6iRY6VunDDIjPA5mATQQITVXOECHqkZB5E2pzWSC2AaUp0wZCWR");
            myHeaders.append("Content-Type", "application/json");

            const requests = phoneNumbers.map(async (phone_no, index) => {
                const raw = JSON.stringify({
                    "messaging_product": "whatsapp",
                    "recipient_type": "individual",
                    "to": `91${phone_no}`,
                    "type": "template",
                    "template": {
                        "name": "workshop_code",
                        "language": {
                            "code": "en",
                            "policy": "deterministic"
                        },
                        "components": [
                            {
                                "type": "header",
                                "parameters": [
                                    {
                                        "type": "image",
                                        "image": { "link": "https://firebasestorage.googleapis.com/v0/b/youvati.appspot.com/o/code.jpeg?alt=media&token=017f45e2-2d1b-4235-bdd7-74fa3410eb35" }
                                    }
                                ]
                            },
                            {
                                "type": "body",
                                "parameters": [
                                    { "type": "text", "text": text2 },
                                    { "type": "text", "text": text1 }
                                ]
                            }
                        ]
                    }
                });

                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                try {
                    const response = await fetch("https://graph.facebook.com/v14.0/104489945783566/messages", requestOptions);
                    const result_1 = await response.text();
                    console.log(result_1);
                } catch (error) {
                    console.log('error', error);
                }

                // Update progress
                setProgress(((index + 1) / phoneNumbers.length) * 100);
            });

            Promise.all(requests)
                .then(() => {
                    setLoading(false);
                    alert("Messages sent successfully!");
                })
                .catch(error => {
                    setLoading(false);
                    alert("An error occurred while sending messages.");
                    console.error(error);
                });
        }
    };

    const sendInAppNotification = async () => {
        const { value: message } = await Swal.fire({
            title: "Enter Notification Message",
            input: 'text',
            inputPlaceholder: 'Enter your message here...',
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return 'You need to write something!';
                }
            }
        });

        if (message) {
            setLoading(true);
            const notifications = docIds.map(async (id) => {
                return db.collection("notifications").add({
                    'created_date': new Date(),
                    image_url: "",
                    link: "WorkshopList",
                    message: message,
                    module: "workshop",
                    personal: id,
                    seen: [],
                    status: 1,
                    view: false
                });
            });

            Promise.all(notifications)
                .then(() => {
                    setLoading(false);
                    Swal.fire("Notifications sent successfully!");
                })
                .catch((error) => {
                    setLoading(false);
                    Swal.fire("An error occurred while sending notifications.");
                    console.error(error);
                });
        }
    };

    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
                <div className="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    <Link to="/admin_panel">
                        <img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} />
                    </Link>
                    <input type="file" onChange={handleFileUpload} className="btn btn-dark" />
                </div>
                <div class="p-2">
                    {loading && <div>Loading...</div>}
                    {data.length > 0 ? <>
                        {!loading && (
                            <>
                                <button className="btn btn-dark" onClick={sendWhatsapp} style={{ marginRight: "1rem" }}>Send WhatsApp Messages</button>
                                <button className="btn btn-dark" onClick={sendInAppNotification}>Send In-App Notification</button>
                            </>
                        )}
                    </> : <></>}

                    <table class="table align-items-center mb-0">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Doc ID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((row, index) => (
                                <tr key={index}>
                                    <td>{row.Name}</td>
                                    <td>{row.Phone}</td>
                                    <td>{row.Regid}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </main>
        </>
    );
}

export default SendCertificateCode;


// import React, { useState } from 'react';
// import { useNavigate, Link } from 'react-router-dom';
// import { read, utils } from 'xlsx';
// import Swal from 'sweetalert2';

// function SendCertificateCode() {
//     const navigate = useNavigate();
//     const [data, setData] = useState([]);
//     const [phoneNumbers, setPhoneNumbers] = useState([]);
//     const [docIds, setDocIds] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [progress, setProgress] = useState(0);

//     const handleFileUpload = (event) => {
//         const file = event.target.files[0];
//         const reader = new FileReader();
//         reader.onload = (e) => {
//             const workbook = read(e.target.result);
//             const sheetName = workbook.SheetNames[0];
//             const sheet = workbook.Sheets[sheetName];
//             const jsonData = utils.sheet_to_json(sheet);
//             setData(jsonData);
//             const phones = jsonData.map(item => item.Phone);
//             const ids = jsonData.map(item => item.Regid);
//             setPhoneNumbers(phones);
//             setDocIds(ids);
//             console.log("Phone Numbers:", phones);
//             console.log("Doc IDs:", ids);
//         };
//         reader.readAsArrayBuffer(file);
//     };

//     const sendWhatsapp = async () => {
//         const { value: formValues } = await Swal.fire({
//             title: "Multiple inputs",
//             html: `
//                 <input id="swal-input1" class="swal2-input">
//                 <input id="swal-input2" class="swal2-input">
//             `,
//             focusConfirm: false,
//             preConfirm: () => {
//                 const input1 = document.getElementById("swal-input1").value;
//                 const input2 = document.getElementById("swal-input2").value;

//                 if (!input1 || !input2) {
//                     Swal.showValidationMessage("Both fields are required.");
//                     return false;
//                 }

//                 return [input1, input2];
//             }
//         });

//         if (formValues) {
//             Swal.fire(JSON.stringify(formValues));
//             const [text1, text2] = formValues;

//             setLoading(true);
//             setProgress(0);
//             const myHeaders = new Headers();
//             myHeaders.append("Authorization", "Bearer EABMKlZCrATXIBAPOZBZBPqsdfot34fq0FTipZBQf3RiXZAapyCOqMay09HfhSxoB8bd5ZA7RUnLWhd0JkXtACJQ682THiDEMhQhyPOkeui8Cb8P72WPDhihUZBCkHtPLSgsmuZA6iRY6VunDDIjPA5mATQQITVXOECHqkZB5E2pzWSC2AaUp0wZCWR");
//             myHeaders.append("Content-Type", "application/json");

//             const requests = phoneNumbers.map(async (phone_no, index) => {
//                 const raw = JSON.stringify({
//                     "messaging_product": "whatsapp",
//                     "recipient_type": "individual",
//                     "to": `91${phone_no}`,
//                     "type": "template",
//                     "template": {
//                         "name": "workshop_code",
//                         "language": {
//                             "code": "en",
//                             "policy": "deterministic"
//                         },
//                         "components": [
//                             {
//                                 "type": "header",
//                                 "parameters": [
//                                     {
//                                         "type": "image",
//                                         "image": { "link": "https://firebasestorage.googleapis.com/v0/b/youvati.appspot.com/o/code.jpeg?alt=media&token=017f45e2-2d1b-4235-bdd7-74fa3410eb35" }
//                                     }
//                                 ]
//                             },
//                             {
//                                 "type": "body",
//                                 "parameters": [
//                                     { "type": "text", "text": text2 },
//                                     { "type": "text", "text": text1 }
//                                 ]
//                             }
//                         ]
//                     }
//                 });

//                 const requestOptions = {
//                     method: 'POST',
//                     headers: myHeaders,
//                     body: raw,
//                     redirect: 'follow'
//                 };

//                 try {
//                     const response = await fetch("https://graph.facebook.com/v14.0/104489945783566/messages", requestOptions);
//                     const result_1 = await response.text();
//                     console.log(result_1);
//                 } catch (error) {
//                     console.log('error', error);
//                 }

//                 // Update progress
//                 setProgress(((index + 1) / phoneNumbers.length) * 100);
//             });

//             Promise.all(requests)
//                 .then(() => {
//                     setLoading(false);
//                     alert("Messages sent successfully!");
//                 })
//                 .catch(error => {
//                     setLoading(false);
//                     alert("An error occurred while sending messages.");
//                     console.error(error);
//                 });
//         }
//     };

//     return (
//         <>
//             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
//                 <div className="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
//                     <Link to="/admin_panel">
//                         <img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} />
//                     </Link>
//                     <input type="file" onChange={handleFileUpload} />
//                 </div>
//                 <div class="p-2">
//                     {loading && <div>Sending messages... {Math.round(progress)}%</div>}
//                     {!loading && <button onClick={sendWhatsapp}>Send WhatsApp Messages</button>}
//                     <table class="table align-items-center mb-0">
//                         <thead>
//                             <tr>
//                                 <th>Name</th>
//                                 <th>Phone</th>
//                                 <th>Doc ID</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {data.map((row, index) => (
//                                 <tr key={index}>
//                                     <td>{row.Name}</td>
//                                     <td>{row.Phone}</td>
//                                     <td>{row.Regid}</td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </main>
//         </>
//     );
// }

// export default SendCertificateCode;



// // import React, { useState } from 'react';
// // import { useNavigate, Link } from 'react-router-dom';
// // import { read, utils } from 'xlsx';
// // import { CSVLink } from "react-csv";
// // import Swal from 'sweetalert2';

// // function SendCertificateCode() {
// //     const navigate = useNavigate();
// //     const [data, setData] = useState([]);
// //     const [phoneNumbers, setPhoneNumbers] = useState([]);
// //     const [docIds, setDocIds] = useState([]);
// //     const [loading, setLoading] = useState(false);

// //     const handleFileUpload = (event) => {
// //         const file = event.target.files[0];
// //         const reader = new FileReader();
// //         reader.onload = (e) => {
// //             const workbook = read(e.target.result);
// //             const sheetName = workbook.SheetNames[0];
// //             const sheet = workbook.Sheets[sheetName];
// //             const jsonData = utils.sheet_to_json(sheet);
// //             setData(jsonData);
// //             const phones = jsonData.map(item => item.Phone);
// //             const ids = jsonData.map(item => item.Regid);
// //             setPhoneNumbers(phones);
// //             setDocIds(ids);
// //             console.log("Phone Numbers:", phones);
// //             console.log("Doc IDs:", ids);
// //         };
// //         reader.readAsArrayBuffer(file);
// //     };

// //     const sendWhatsapp = async () => {

// //         const { value: formValues } = await Swal.fire({
// //             title: "Multiple inputs",
// //             html: `
// //                 <input id="swal-input1" class="swal2-input">
// //                 <input id="swal-input2" class="swal2-input">
// //             `,
// //             focusConfirm: false,
// //             preConfirm: () => {
// //                 const input1 = document.getElementById("swal-input1").value;
// //                 const input2 = document.getElementById("swal-input2").value;

// //                 if (!input1 || !input2) {
// //                     Swal.showValidationMessage("Both fields are required.");
// //                     return false;
// //                 }

// //                 return [input1, input2];
// //             }
// //         });

// //         if (formValues) {
// //             Swal.fire(JSON.stringify(formValues));
// //             const [text1, text2] = formValues;

// //             setLoading(true);
// //             const myHeaders = new Headers();
// //             myHeaders.append("Authorization", "Bearer EABMKlZCrATXIBAPOZBZBPqsdfot34fq0FTipZBQf3RiXZAapyCOqMay09HfhSxoB8bd5ZA7RUnLWhd0JkXtACJQ682THiDEMhQhyPOkeui8Cb8P72WPDhihUZBCkHtPLSgsmuZA6iRY6VunDDIjPA5mATQQITVXOECHqkZB5E2pzWSC2AaUp0wZCWR");
// //             myHeaders.append("Content-Type", "application/json");

// //             const requests = phoneNumbers.map(async phone_no => {
// //                 const raw = JSON.stringify({
// //                     "messaging_product": "whatsapp",
// //                     "recipient_type": "individual",
// //                     "to": `91${phone_no}`,
// //                     "type": "template",
// //                     "template": {
// //                         "name": "workshop_code",
// //                         "language": {
// //                             "code": "en",
// //                             "policy": "deterministic"
// //                         },
// //                         "components": [
// //                             {
// //                                 "type": "header",
// //                                 "parameters": [
// //                                     {
// //                                         "type": "image",
// //                                         "image": { "link": "https://firebasestorage.googleapis.com/v0/b/youvati.appspot.com/o/code.jpeg?alt=media&token=017f45e2-2d1b-4235-bdd7-74fa3410eb35" }
// //                                     }
// //                                 ]
// //                             },
// //                             {
// //                                 "type": "body",
// //                                 "parameters": [
// //                                     { "type": "text", "text": text2 },
// //                                     { "type": "text", "text": text1 }
// //                                 ]
// //                             }
// //                         ]
// //                     }
// //                 });

// //                 const requestOptions = {
// //                     method: 'POST',
// //                     headers: myHeaders,
// //                     body: raw,
// //                     redirect: 'follow'
// //                 };

// //                 try {
// //                     const response = await fetch("https://graph.facebook.com/v14.0/104489945783566/messages", requestOptions);
// //                     const result_1 = await response.text();
// //                     console.log(result_1);
// //                 } catch (error) {
// //                     console.log('error', error);
// //                 }
// //             });

// //             Promise.all(requests)
// //                 .then(() => {
// //                     setLoading(false);
// //                     alert("Messages sent successfully!");
// //                 })
// //                 .catch(error => {
// //                     setLoading(false);
// //                     alert("An error occurred while sending messages.");
// //                     console.error(error);
// //                 });
// //         }



// //         // const { value: formValues } = await Swal.fire({
// //         //     title: "Multiple inputs",
// //         //     html: `
// //         //         <input id="swal-input1" class="swal2-input">
// //         //         <input id="swal-input2" class="swal2-input">
// //         //     `,
// //         //     focusConfirm: false,
// //         //     preConfirm: () => {
// //         //         const input1 = document.getElementById("swal-input1").value;
// //         //         const input2 = document.getElementById("swal-input2").value;

// //         //         if (!input1 || !input2) {
// //         //             Swal.showValidationMessage("Both fields are required.");
// //         //             return false;
// //         //         }

// //         //         return [input1, input2];
// //         //     }
// //         // });

// //         // if (formValues) {
// //         //     Swal.fire(JSON.stringify(formValues));
// //         // }

// //         // setLoading(true);
// //         // const myHeaders = new Headers();
// //         // myHeaders.append("Authorization", "Bearer EABMKlZCrATXIBAPOZBZBPqsdfot34fq0FTipZBQf3RiXZAapyCOqMay09HfhSxoB8bd5ZA7RUnLWhd0JkXtACJQ682THiDEMhQhyPOkeui8Cb8P72WPDhihUZBCkHtPLSgsmuZA6iRY6VunDDIjPA5mATQQITVXOECHqkZB5E2pzWSC2AaUp0wZCWR");
// //         // myHeaders.append("Content-Type", "application/json");

// //         // const requests = phoneNumbers.map(async phone_no => {
// //         //     const raw = JSON.stringify({
// //         //         "messaging_product": "whatsapp",
// //         //         "recipient_type": "individual",
// //         //         "to": `91${phone_no}`,
// //         //         "type": "template",
// //         //         "template": {
// //         //             "name": "workshop_code",
// //         //             "language": {
// //         //                 "code": "en_US",
// //         //                 "policy": "deterministic"
// //         //             },
// //         //             "components": [
// //         //                 {
// //         //                     "type": "header",
// //         //                     "parameters": [
// //         //                         {
// //         //                             "type": "image",
// //         //                             "image": { "link": "https://firebasestorage.googleapis.com/v0/b/youvati.appspot.com/o/code.jpeg?alt=media&token=017f45e2-2d1b-4235-bdd7-74fa3410eb35" }
// //         //                         }
// //         //                     ]
// //         //                 },
// //         //                 {
// //         //                     "type": "body",
// //         //                     "parameters": [
// //         //                         { "type": "text", "text": "pass text 1" },
// //         //                         { "type": "text", "text": "pass text 2" }
// //         //                     ]
// //         //                 }
// //         //             ]
// //         //         }
// //         //     });

// //         //     const requestOptions = {
// //         //         method: 'POST',
// //         //         headers: myHeaders,
// //         //         body: raw,
// //         //         redirect: 'follow'
// //         //     };

// //         //     try {
// //         //         const response = await fetch("https://graph.facebook.com/v14.0/104489945783566/messages", requestOptions);
// //         //         const result_1 = await response.text();
// //         //         console.log(result_1);
// //         //     } catch (error) {
// //         //         console.log('error', error);
// //         //     }
// //         // });

// //         // Promise.all(requests)
// //         //     .then(() => {
// //         //         setLoading(false);
// //         //         alert("Messages sent successfully!");
// //         //     })
// //         //     .catch(error => {
// //         //         setLoading(false);
// //         //         alert("An error occurred while sending messages.");
// //         //         console.error(error);
// //         //     });
// //     };

// //     return (
// //         <>
// //             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
// //                 <div className="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
// //                     <Link to="/admin_panel">
// //                         <img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} />
// //                     </Link>
// //                     <input type="file" onChange={handleFileUpload} />
// //                 </div>
// //                 <div class="p-2">
// //                     {loading && <div>Loading...</div>}
// //                     {!loading && <button onClick={sendWhatsapp}>Send WhatsApp Messages</button>}
// //                     <table class="table align-items-center mb-0">
// //                         <thead>
// //                             <tr>
// //                                 <th>Name</th>
// //                                 <th>Phone</th>
// //                                 <th>Doc ID</th>
// //                             </tr>
// //                         </thead>
// //                         <tbody>
// //                             {data.map((row, index) => (
// //                                 <tr key={index}>
// //                                     <td>{row.Name}</td>
// //                                     <td>{row.Phone}</td>
// //                                     <td>{row.Regid}</td>
// //                                 </tr>
// //                             ))}
// //                         </tbody>
// //                     </table>
// //                     {/* <CSVLink
// //                         data={data.map(item => ({ Name: item.Name, Phone: item.Phone, Regid: item.Regid }))}
// //                         headers={[
// //                             { label: "Name", key: "Name" },
// //                             { label: "Phone", key: "Phone" },
// //                             { label: "Doc ID", key: "Regid" }
// //                         ]}
// //                         filename="uploaded_data.csv"
// //                         className="btn btn-dark"
// //                         style={{ marginTop: '10px' }}
// //                     >
// //                         Download Excel
// //                     </CSVLink> */}
// //                 </div>
// //             </main>
// //         </>
// //     );
// // }

// // export default SendCertificateCode;




// // // import React, { useState } from 'react';
// // // import * as XLSX from 'xlsx';
// // // import { CSVLink } from 'react-csv';

// // // const SendCertificateCode = () => {
// // //     const [excelData, setExcelData] = useState([]);
// // //     const [phoneNumbers, setPhoneNumbers] = useState([]);
// // //     const [ids, setIds] = useState([]);

// // //     const handleFileUpload = (event) => {
// // //         const file = event.target.files[0];
// // //         if (file) {
// // //             const reader = new FileReader();
// // //             reader.onload = (e) => {
// // //                 const data = new Uint8Array(e.target.result);
// // //                 const workbook = XLSX.read(data, { type: 'array' });
// // //                 const worksheet = workbook.Sheets[workbook.SheetNames[0]];
// // //                 const jsonData = XLSX.utils.sheet_to_json(worksheet);
// // //                 console.log('Parsed JSON Data:', jsonData); // Log the parsed JSON data

// // //                 setExcelData(jsonData);

// // //                 const phones = jsonData.map(row => row.Phone);
// // //                 const ids = jsonData.map(row => row.Regid);
// // //                 setPhoneNumbers(phones);
// // //                 setIds(ids);

// // //                 console.log('Phone Numbers:', phones); // Log the phone numbers
// // //                 console.log('IDs:', ids); // Log the IDs
// // //             };
// // //             reader.readAsArrayBuffer(file);
// // //         }
// // //     };

// // //     const headers = [
// // //         { label: "Name", key: "Name" },
// // //         { label: "Phone", key: "Phone" },
// // //         { label: "Regid", key: "Regid" }
// // //     ];

// // //     return (
// // //         <>
// // //             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
// // //                 <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
// // //                     <input type="file" onChange={handleFileUpload} class="form-control-file" />
// // //                     <CSVLink
// // //                         data={excelData}
// // //                         headers={headers}
// // //                         filename="uploaded_data.csv"
// // //                         class="btn btn-dark"
// // //                         style={{ height: "2rem" }}
// // //                     >
// // //                         Download Excel
// // //                     </CSVLink>
// // //                 </div>
// // //                 <div class="p-2">
// // //                     <div class="card">
// // //                         <div class="card-header pb-0 px-3">
// // //                             <h6 class="mb-2">Uploaded Data</h6>
// // //                         </div>
// // //                         <div class="card-body">
// // //                             <div class="table-responsive">
// // //                                 <table class="table table-bordered table-hover">
// // //                                     <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
// // //                                         <tr>
// // //                                             <th>Name</th>
// // //                                             <th>Phone</th>
// // //                                             <th>Regid</th>
// // //                                         </tr>
// // //                                     </thead>
// // //                                     <tbody>
// // //                                         {excelData.map((item, index) => (
// // //                                             <tr key={index}>
// // //                                                 <td>{item.Name}</td>
// // //                                                 <td>{item.Phone}</td>
// // //                                                 <td>{item.Regid}</td>
// // //                                             </tr>
// // //                                         ))}
// // //                                     </tbody>
// // //                                 </table>
// // //                             </div>
// // //                         </div>
// // //                     </div>
// // //                 </div>
// // //             </main>
// // //         </>
// // //     );
// // // };

// // // export default SendCertificateCode;

