import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import DataTable from 'react-data-table-component';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";


function BulkWhatsapp() {
    let params = useParams();
    const navigate = useNavigate();
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [ShowProgress, setShowProgress] = useState(false);



    const [show, setshow] = useState(true);

    const [showExcel, setshowExcel] = useState(false);

    const [imgUrl, setImgUrl] = useState(null);
    const [progresspercent, setProgresspercent] = useState(0);


    var _cartList = [

    ];
    const [listData, setListData] = useState([]);

    const processData = dataString => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"')
                            d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d;
                    }
                }

                // remove the blank rows
                if (Object.values(obj).filter(x => x).length > 0) {
                    list.push(obj);
                }
            }
        }

        // prepare columns list from headers
        const columns = headers.map(c => ({
            name: c,
            selector: c,
        }));

        setData(list);
        setColumns(columns);
        console.log(list)
    }

    const [showData, setShowData] = useState([])
    const [showData1, setShowData1] = useState([])


    const upload = () => {
        // db.collection("consumables_data").add({ _cartList }).then(() => {
        //     console.log('Submitted Successfully');
        // })
        _cartList.map((e, index) => {
            console.log(e)
            // db.collection("consumables_data").add(e).then(() => {
            //     console.log('Submitted Successfully');
            // })
        })
    }

    // handle file upload
    const handleFileUpload = e => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(data);
        };
        reader.readAsBinaryString(file);
    }
    const [num, setNum] = useState(0);


    const wap = [
        '9900646131'
    ]


    const submitdata = async (e) => {
        for (let i = 0; i < data.length; i++) {
            const user = data[i];
            // console.log(user.phone_no)
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer EABMKlZCrATXIBAPOZBZBPqsdfot34fq0FTipZBQf3RiXZAapyCOqMay09HfhSxoB8bd5ZA7RUnLWhd0JkXtACJQ682THiDEMhQhyPOkeui8Cb8P72WPDhihUZBCkHtPLSgsmuZA6iRY6VunDDIjPA5mATQQITVXOECHqkZB5E2pzWSC2AaUp0wZCWR");
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify(
                {
                    "messaging_product": "whatsapp",
                    "recipient_type": "individual",
                    "to": `91${user.phone_no}`,
                    "type": "template",
                    "template": {
                        "name": "offline_certificate_download",
                        "language": {
                            "code": "en_US",
                            "policy": "deterministic"
                        },
                        "components": [
                            {
                                "type": "header",
                                "parameters": [
                                    {
                                        "type": "image",
                                        "image": { "link": "https://firebasestorage.googleapis.com/v0/b/youvati.appspot.com/o/Download%20Certificate%20Banner%20(3)%20(1).jpg?alt=media&token=f637ab51-0683-49e4-a50d-63deac40aa62" }
                                    }
                                ]
                            },
                            {
                                "type": "body",
                                "parameters": [
                                    { "type": "text", "text": `${user.name}` },
                                    { "type": "text", "text": `${user.link}` },
                                ]
                            }
                        ]
                    }
                }
            );

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch("https://graph.facebook.com/v14.0/104489945783566/messages", requestOptions)
                .then(response => response.text())
                .then(result => console.log(result))
                .catch(error => {
                    console.log('error', error)
                    console.log(user)
                });

            // try {
            //     const response = await axios.post("YOUR_WHATSAPP_API_ENDPOINT", {
            //         // Your WhatsApp API payload here
            //         // You can use the 'user' object to get phone number and link
            //     });

            //     console.log(`WhatsApp message sent to ${user.phone}`, response.data);

            //     // Update progress
            //     setProgresspercent((i + 1) / data.length * 100);
            // } catch (error) {
            //     console.error(`Error sending WhatsApp message to ${user.phone}`, error);
            // }
        }
        // wap.map((user, index) => {
        //     console.log(`91${user}`)
        //     var myHeaders = new Headers();
        //     myHeaders.append("Authorization", "Bearer EABMKlZCrATXIBAPOZBZBPqsdfot34fq0FTipZBQf3RiXZAapyCOqMay09HfhSxoB8bd5ZA7RUnLWhd0JkXtACJQ682THiDEMhQhyPOkeui8Cb8P72WPDhihUZBCkHtPLSgsmuZA6iRY6VunDDIjPA5mATQQITVXOECHqkZB5E2pzWSC2AaUp0wZCWR");
        //     myHeaders.append("Content-Type", "application/json");

        //     var raw = JSON.stringify(
        //         {
        //             "messaging_product": "whatsapp",
        //             "recipient_type": "individual",
        //             "to": `91${user}`,
        //             "type": "template",
        //             "template": {
        //                 "name": "newlink",
        //                 "language": {
        //                     "code": "en_US",
        //                     "policy": "deterministic"
        //                 },
        //                 "components": [
        //                     {
        //                         "type": "button",
        //                         "sub_type": "url",
        //                         "index": 2,
        //                         "parameters": [
        //                             {
        //                                 "type": "text",
        //                                 "text": "https://www.google.com"
        //                             }
        //                         ]
        //                     }
        //                 ]
        //             }
        //         }
        //     );

        //     var requestOptions = {
        //         method: 'POST',
        //         headers: myHeaders,
        //         body: raw,
        //         redirect: 'follow'
        //     };

        //     fetch("https://graph.facebook.com/v14.0/104489945783566/messages", requestOptions)
        //         .then(response => response.text())
        //         .then(result => console.log(result))
        //         .catch(error => {
        //             console.log('error', error)
        //             console.log(user)
        //         });
        // })
    };

    const cancleAll = () => {
        setColumns([])
        setData([])
    }



    useEffect(() => {
        // alert(params.id)
    }, []);



    return (
        <>
            {/* <button onClick={upload}>Send</button> */}


            <div id="" class="m-3">

                <div class="card-body">
                    <div class="form-group justify-content-end row">
                        <div class="col-lg-12">
                            <div class="form-control dropzone dropzone-default dropzone-primary dz-clickable h-auto px-4 py-2" style={{ background: "aquamarine", textAlign: "center" }}>
                                <div class="dropzone-msg dz-message needsclick" style={{ borderStyle: "dashed", padding: "1rem", borderColor: "orange" }}>
                                    <input type="file" accept=".csv,.xlsx,.xls" onChange={handleFileUpload} />
                                    <span class="font-size-base"><i class="fas fa-cloud-upload-alt icon-md mr-1"></i>Drop files here or click.</span><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DataTable
                    pagination
                    highlightOnHover
                    columns={columns}
                    data={data}
                />

                {data.length > 0 ? <> <div class="card-footer d-flex justify-content-end py-2">
                    <button class="btn btn-success mr-2" onClick={() => submitdata()}>Upload Data</button>
                    <div class="btn btn-secondary" role="button" onClick={() => cancleAll()}>Cancel</div>
                </div></> : null
                }

                {ShowProgress ? <><h2 style={{ background: "darkorange", padding: "1rem", textAlign: "center", paddingBottom: "1rem", color: "white" }}>{num == data.length ? "Data Uploaded" : "Uploading Data"} - {num}/{data.length}</h2></> : ""}
            </div>
            {/* <button class="btn btn-success mr-2" onClick={() => submitdata()}>Upload Data</button> */}
        </>


    );
}
export default BulkWhatsapp;